import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import styled from "styled-components";

const UL = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
const LI = styled.li``;
const Item = styled.div`
  display: flex;
  padding: 12px 18px;
  padding-left: ${(props) => `${props.dept * 18}px`};
  align-items: center;
`;
const Label = styled.span`
  width: 100%;
  display: block;
  cursor: pointer;
`;
// const Arrow = styled.span`
//   display: flex;
//   height: 25px;
//   width: 35px;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;

//   &::after {
//     content: "";
//     width: 0;
//     height: 0;
//     border-left: 4px solid transparent;
//     border-right: 4px solid transparent;

//     border-top: 4px solid #000;

//     transform: ${(props) => (props.toggle ? "rotate(180deg)" : "rotate(0deg)")};
//   }
// `;

const Arrow = styled.span`
  display: flex;
  height: 25px;
  width: 35px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &::after {
    content: "${(props) => (props.toggle ? "<" : ">")}";
    font-size: 22px;
    margin-top: -5px;
    font-weight: 400;
    transform: ${(props) => (props.toggle ? "rotate(-90deg)" : "rotate(0deg)")};
  }
`;


const MultiMenus = ({ menus,className }) => {
  const history = useHistory();
  const [activeMenus, setActiveMenus] = useState([]);

  const handleMenuClick = (data) => {
    history.push(data.path);
    updateActiveMenus([]); //newly added line

    // return <NavLink to={data.path}></NavLink>;
  };

  const handleArrowClick = (menuName) => {
    let newActiveMenus = [...activeMenus];

    if (newActiveMenus.includes(menuName)) {
      var index = newActiveMenus.indexOf(menuName);
      if (index > -1) {
        newActiveMenus.splice(index, 1);
      }
    } else {
      newActiveMenus.push(menuName);
    }

    setActiveMenus(newActiveMenus);
    updateActiveMenus(newActiveMenus); // newly added line
  };

  // newly added function
  const updateActiveMenus = (newActiveMenus) => {
    setActiveMenus(newActiveMenus);
    localStorage.setItem("activeMenus", JSON.stringify(newActiveMenus));
  };

  //newly added useeffect
  useEffect(() => {
    const storedActiveMenus = localStorage.getItem("activeMenus");
    if (storedActiveMenus) {
      setActiveMenus(JSON.parse(storedActiveMenus));
    }
  }, []);

  const ListMenu = ({
    dept,
    data,
    hasSubMenu,
    menuName,
    menuIndex,
    isActive,
  }) => (
    <div className="video_side">
      <Item
        onClick={() => handleArrowClick(menuName)}
        toggle={activeMenus.includes(menuName)}
        dept={dept}
      > 
      <div className="notes_bg">
          <h3 className="d-flex ml-0">
          <div className="mr-2"> {data.icon} </div>
          <Label
            className={isActive ? `active_label ${className}` : " "}
            onClick={() => handleMenuClick(data)}
          >
            {data.label}{" "}
          </Label>
        </h3>
        </div>
        <h4>
          <Label
            className={isActive ? "active_label" : " "}
            onClick={() => handleMenuClick(data)}
          >
            {data.label1}{" "}
          </Label>
        </h4>
        <h5>
          <Label
            className={isActive ? "active_label" : " "}
            onClick={() => handleMenuClick(data)}
          >
            {data.label2}{" "}
          </Label>
        </h5>
        {hasSubMenu && (
          <Arrow
            onClick={() => handleArrowClick(menuName)}
            toggle={activeMenus.includes(menuName)}
          />
        )}
      </Item>
      {hasSubMenu && (
        <h4>
          <SubMenu
            dept={dept}
            data={data.submenu}
            toggle={activeMenus.includes(menuName)}
            // path={"/"}
            menuIndex={menuIndex}
          />
        </h4>
      )}
    </div>
  );

  const SubMenu = ({ dept, data, toggle, menuIndex }) => {
    if (!toggle) {
      return null;
    }

    dept = dept + 1;

    return (
      <UL>
        {data.map((menu, index) => {
          const menuName = `sidebar-submenu-${dept}-${menuIndex}-${index}`;

          return (
            <ListMenu
              dept={dept}
              isActive={menu?.path === window.location.pathname}
              data={menu}
              hasSubMenu={menu.submenu}
              menuName={menuName}
              key={menuName}
              menuIndex={index}
              activeMenus={activeMenus} //newly added line
            />
          );
        })}
      </UL>
    );
  };

  return (
    <UL>
      {menus.map((menu, index) => {
        const dept = 1;
        const menuName = `sidebar-menu-${dept}-${index}`;

        return (
          <ListMenu
            dept={dept}
            data={menu}
            hasSubMenu={menu.submenu}
            isActive={menu?.path === window.location.pathname}
            menuName={menuName}
            key={menuName}
            menuIndex={index}
          />
        );
      })}
    </UL>
  );
};

export default MultiMenus;
