import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
// import ReactGA from "react-ga4";

/*------ Pages-----*/

import { NotificationContainer } from "react-notifications";
import ElementsAssetsPage from "./Pages/ElementsAssetsPage";
import StepGroupAssetPage from "./Pages/StepGroupAssetPage";
import ExcelPage from "./Pages/ExcelPage";
import ListOfElements from "./Pages/ListOfElements";
import AssetDescriptionPage from "./Pages/AssetDescriptionPage";
import AssetSearchResultPage from "./Pages/AssetSearchResultPage";
import ElementsDescriptionPage from "./Pages/ElementsDescriptionPage";
import StepGroupDescriptionPage from "./Pages/StepGroupDescriptionPage";
import FireflinkAssetNew from "./Pages/FireflinkAssetNew";
import ProductsPage from "./Pages/product_page/productPage";
// import ReleaseNotesTen from "./components/release-notes/ReleaseNoteTen";
import ListOfStepGroup from "./Pages/ListOfStepGroup";
// import ReleaseNotesTwoPointZero from "./components/release-notes/ReleaseNotesTwoPointZero";
// import TwoPointZeroPointOne from "./components/release-notes/TwoPointZeroPointOne";
import SafariBrowserLimitation from "./Pages/SafariBrowserLimitation";
import FloatingDemo from "./components/Common/FloatingDemo";
// import TwoPointZeroPointTwo from "./components/release-notes/TwoPointZeroPointTwo";

const DataPolicy = lazy(() => import("./Pages/DataPolicy"));
const BlogHome = lazy(() => import("./Pages/BlogHome"));
const Bloglist = lazy(() => import("./Pages/Bloglist"));

const UserAcceptanceTestingBlog = lazy(() =>
  import("./Pages/blogs/UserAcceptanceTestingBlog")
);

const MovingForwardWithoutTestingBlog = lazy(() =>
  import("./Pages/blogs/MovingForwardWithouTestingBlog")
);

const DoesRoiBenefitBlog = lazy(() =>
  import("./Pages/blogs/DoesRoiBenefitBlog")
);
const ScalableOnDemandTesting = lazy(() =>
  import("./Pages/blogs/ScalableOnDemandTestingBlog")
);
const ImpactOfUi = lazy(() => import("./Pages/blogs/ImpactOfUiBlog"));
const StreamliningDebugging = lazy(() =>
  import("./Pages/blogs/StreamLiningDebuggingBlog")
);
const EnchancingTestAutomation = lazy(() =>
  import("./Pages/blogs/EnchancingTestAutomationBlog")
);
const StepTagging = lazy(() => import("./Pages/blogs/StepTaggingBlog"));
const SimplifyingTestAutomation = lazy(() =>
  import("./Pages/blogs/SimplifyingTestAutomationBlog")
);
const ElevatingTestAutomation = lazy(() =>
  import("./Pages/blogs/ElevatingTestAutomationBlog")
);
const SimplifyingRegressionTesting = lazy(() =>
  import("./Pages/blogs/SimplifyingRegressionTestingBlog")
);
const SimplifyingApiTesting = lazy(() =>
  import("./Pages/blogs/SimplifyingApiTestingBlog")
);

const MobileTestingSimplified = lazy(() =>
  import("./Pages/blogs/MobileTestingSimplifiedBlog")
);

const ChallengesFacedByQa = lazy(() =>
  import("./Pages/blogs/ChallengesFacedByQaBlog")
);

const PowerOfNoCode = lazy(() => import("./Pages/blogs/PowerOfNoCodeBlog"));

const EssentialsOfEndtoEndTesting = lazy(() =>
  import("./Pages/blogs/EssentialsOfEndtoEndTestingBlog")
);

const MasteringUnitTesting = lazy(() =>
  import("./Pages/blogs/MasteringUnitTestingBlog")
);

const SimplifyingEndtoEndTesting = lazy(() =>
  import("./Pages/blogs/SimplifyingEndtoEndTestingBlog")
);

const EndtoEndTesting = lazy(() => import("./Pages/blogs/EndToEndTestingBlog"));

const MasteringEndtoEndTesting = lazy(() =>
  import("./Pages/blogs/MasteringEndtoEndTestingBlog")
);

const OptimisingTesting = lazy(() =>
  import("./Pages/blogs/OptimisingTestingBlog")
);

const CommonTestingPitfalls = lazy(() =>
  import("./Pages/blogs/CommonTestingPitfallsBlog")
);

const CrucialRoleOfUnitTesting = lazy(() =>
  import("./Pages/blogs/CrucialRoleOfUnitTestingBlog")
);

const EnhancingAutomationTesting = lazy(() =>
  import("./Pages/blogs/EnchancingAutomationTestingBlog")
);

const qaAutomation = lazy(() => import("./Pages/blogs/QaAutomationBlog"));
const selectingRightToolsQa = lazy(() =>
  import("./Pages/blogs/SelectingRightToolsQaBlog")
);
const MasteringDebugging = lazy(() =>
  import("./Pages/blogs/MasteringDebuggingBlog")
);
const SoftwareTestingLifeCycle = lazy(() =>
  import("./Pages/blogs/SoftwareTestingLifeCycleBlog")
);
const SoftwareTestingBasics = lazy(() =>
  import("./Pages/blogs/SoftwareTestingBasicsBlog")
);
const ComparativeAnalysis = lazy(() =>
  import("./Pages/blogs/ComparativeAnalysisBlog")
);
const BestPracticesRobustTesing = lazy(() =>
  import("./Pages/blogs/BestPracticesRobustTestingBlog")
);

const HowtoMigrate = lazy(() => import("./Pages/blogs/HowToMigrateBlog"));

const QualityAssuranceSolutions = lazy(() =>
  import("./Pages/blogs/QualityAssuranceSolutionsBlog")
);

const QaAutomationEmergingTrends = lazy(() =>
  import("./Pages/blogs/QaAutomationEmergingTrendsBlog")
);

const ExploratoryTesting = lazy(() =>
  import("./Pages/blogs/ExploratoryTestingBlog")
);

const RegressionTesting = lazy(() =>
  import("./Pages/blogs/RegressionTestingBlog")
);

const UnitTesting = lazy(() => import("./Pages/blogs/UnitTestingBlog"));
const UnitTestingInCICDEnv = lazy(() =>
  import("./Pages/blogs/UnitTestingIncicdenv")
);

const QaTestingMethods = lazy(() =>
  import("./Pages/blogs/QaTestingMethodsBlog")
);
const IntegrationUnitTesting = lazy(() =>
  import("./Pages/blogs/IntegrationUnitTestingBlog")
);

const IntroToTestAutomation = lazy(() =>
  import("./Pages/blogs/IntroToTestAutomationBlog")
);
const AutomationTestingBestPracticeBlog = lazy(() =>
  import("./Pages/blogs/AutomationTestingBestPracticeBlog")
);
const QaTestingProcedures = lazy(() =>
  import("./Pages/blogs/QaTestingProceduresBlog")
);
const EmulatorsAndSimulators = lazy(() =>
  import("./Pages/blogs/EmulatorSimulator")
);

const MobileAppTesting = lazy(() =>
  import("./Pages/blogs/MobileAppTestingBlog")
);
const BestEndToEndTesting = lazy(() =>
  import("./Pages/blogs/BestEndToEndTestingBlog")
);
const IntegratingAutomationTestingBlog = lazy(() =>
  import("./Pages/blogs/IntegratingAutomationTestingBlog")
);
const AcceptanceTestingBlog = lazy(() =>
  import("./Pages/blogs/AcceptanceTestingBlog")
);
const CloudTestingBlog = lazy(() => import("./Pages/blogs/CloudTestingBlog"));
const WhatIsEndToEnd = lazy(() => import("./Pages/blogs/WhatIsEndToEndBlog"));

const TestCasesTestSuites = lazy(() =>
  import("./Pages/blogs/TestCasesTestSuitesBlog")
);
const CommonChallenges = lazy(() =>
  import("./Pages/blogs/CommonChallengesBlog")
);
const GuideLinesRec = lazy(() => import("./Pages/blogs/GuidelinesRecBlog"));

const QualityAssurance = lazy(() =>
  import("./Pages/blogs/QualityAssuranceBlog")
);
const TopAndroidAutomationBlog = lazy(() =>
  import("./Pages/blogs/TopAndriodAutomationBlog")
);

const FireFlinkWhyItIsBetter = lazy(() =>
  import("./Pages/blogs/FireFlinkWhyItIsBetterBlog")
);
const WebPerformance = lazy(() => import("./Pages/blogs/WebPerformanceBlog"));
const StepByStepGuideBlog = lazy(() =>
  import("./Pages/blogs/StepByStepGuideBlog")
);
const KeyMetricsAutomationTestingBlog = lazy(() =>
  import("./Pages/blogs/KeyMetricsAutomationTestingBlog")
);
const WebPerformanceTestingBlog = lazy(() =>
  import("./Pages/blogs/WebPerformanceTestingBlog")
);
const WebAutomationFrameworkBlog = lazy(() =>
  import("./Pages/blogs/WebAutomationFrameworkBlog")
);
const NonFunctionalTesting = lazy(() =>
  import("./Pages/blogs/NonFunctionalTestingBlog")
);
const SystemTestingBlog = lazy(() => import("./Pages/blogs/SystemTestingBlog"));
const TopAutomationToolBlog = lazy(() =>
  import("./Pages/blogs/TopAutomationToolBlog")
);
const MethodAndTool = lazy(() => import("./Pages/blogs/MethodsAndToolsBlog"));

const FrontEndBackEndTestingBlog = lazy(() =>
  import("./Pages/blogs/FrontEndBackEndTestingBlog")
);
const ManualAutomationTestScriptDifferentBlog = lazy(() =>
  import("./Pages/blogs/ManualAutomationTestScriptDifferentBlog")
);
const IosAutomationToolBlog = lazy(() =>
  import("./Pages/blogs/IosAutomationToolBlog")
);
const FrontEndTestingBlog = lazy(() =>
  import("./Pages/blogs/FrontEndTestingBlog")
);
const TestScriptBlog = lazy(() => import("./Pages/blogs/TestScriptBlog"));
const ComprehensiveEndToEndTesingBlog = lazy(() =>
  import("./Pages/blogs/ComprehensiveEndToEndTesingBlog")
);
const OverviewTestScriptBlog = lazy(() =>
  import("./Pages/blogs/OverviewTestScriptBlog")
);
const DifferentTypesSoftwareTestingBlog = lazy(() =>
  import("./Pages/blogs/DifferentTypesSoftwareTestingBlog")
);
const ElementsAndStructureOfTestScriptBlog = lazy(() =>
  import("./Pages/blogs/ElementsAndStructureOfTestScriptBlog")
);
const UpdatesAndTransformationOfTestsciptBlog = lazy(() =>
  import("./Pages/blogs/UpdatesAndMaintenanceOfTestsciptBlog")
);
const ExploringTopScriptingLanguagesBlog = lazy(() =>
  import("./Pages/blogs/ExploringTopScriptingLanguagesBlog")
);
const ProsConsManualAutmationTestingBlog = lazy(() =>
  import("./Pages/blogs/ProsConsManualAutmationTestingBlog")
);
const ImportancePurposeOfTestingProductDevBlog = lazy(() =>
  import("./Pages/blogs/ImportancePurposeOfTestingProductDevBlog")
);
const LeverageTestScriptCiCdBlog = lazy(() =>
  import("./Pages/blogs/LeverageTestScriptCiCdBlog")
);
const ExploringDifferentTestingPhasesBlog = lazy(() =>
  import("./Pages/blogs/ExploringDifferentTestingPhasesBlog")
);
const ImplementingRegressingTestingBlog = lazy(() =>
  import("./Pages/blogs/ImplementingRegressionTestingBlog")
);
const ScrollToTopRoute = lazy(() => import("./ScrollToTopRoute"));
const homeSecurity = lazy(() => import("./Pages/HomeSecurity"));
const HomeLanding = lazy(() => import("./Pages/HomeLanding"));

const FireCrowd = lazy(() => import("./Pages/firecrowd"));
const HomeCloud = lazy(() =>
  import("./Pages/automation-testing-tool-for-web-application")
);
const Faq = lazy(() => import("./Pages/Faq"));
const SignIn = lazy(() => import("./Pages/SignUp/SignInPage"));
const SignUpMain = lazy(() => import("./Pages/SignUp/SignUpPage"));
const RegistrationSuccessfulPage = lazy(() =>
  import("./Pages/SignUp/RegistrationSuccessfulPage")
);
const ChangePhoneNumber = lazy(() =>
  import("./Pages/SignUp/ChangePhoneNumberPage")
);
const VerificationCode = lazy(() =>
  import("./Pages/SignUp/VerificationCodePage")
);
const ActivationSuccess = lazy(() =>
  import("./Pages/SignUp/ActivationSuccessPage")
);
const ForgotPassword = lazy(() => import("./Pages/SignUp/ForgotPasswordPage"));
const ChangePassword = lazy(() => import("./Pages/SignUp/SetPasswordPage"));
const ResetLinkSent = lazy(() => import("./Pages/SignUp/ResetLinkSentPage"));
const PasswordChangedSuccess = lazy(() =>
  import("./Pages/SignUp/PasswordChangedSuccessPage")
);
const ActivationLinkExpired = lazy(() =>
  import("./Pages/SignUp/ActivationLinkExpiredPage")
);
const ActivationLinkSentSuccess = lazy(() =>
  import("./Pages/SignUp/ActivationLinkSentSuccessPage")
);
const WarningActivation = lazy(() =>
  import("./Pages/SignUp/ActivationWarningPage")
);
const UserActivated = lazy(() => import("./Pages/SignUp/UserActivatedPage"));
const UserAlreadyActivated = lazy(() => import("./Pages/UserAlreadyActivated"));
const SetPasswordLinkExpired = lazy(() =>
  import("./Pages/SignUp/SetPasswordLinkExpiredPage")
);
const ActiveUser = lazy(() => import("./Pages/SignUp/ActiveUserPage"));
const CaseStudy = lazy(() => import("./Pages/CaseStudy"));
const NotFound = lazy(() => import("./Pages/404"));
const ProductFeatures = lazy(() => import("./Pages/product-features"));
const MobileappTest = lazy(() =>
  import("./Pages/automation-testing-tool-for-mobile-application")
);
const ApiTest = lazy(() =>
  import("./Pages/automation-testing-tool-for-api-testing")
);
const DefectManagement = lazy(() => import("./Pages/defect-management"));
const ManualTestCaseManagement = lazy(() =>
  import("./Pages/manual-test-case-management")
);
const UxTesting = lazy(() => import("./Pages/ux-testing"));
const UiTesting = lazy(() => import("./Pages/ui-testing"));
const SalesforceTesting = lazy(() => import("./Pages/sales-force-testing"));
const DesktopAppTesting = lazy(() => import("./Pages/desktop-app-testing"));
const ContactUs = lazy(() => import("./Pages/contact-us"));
const FirePlatform = lazy(() => import("./Pages/fireflink-platform"));
const FireflinkPlatformPrcing = lazy(() =>
  import("./Pages/fireflink-platform-pricing")
);
const FireflinkFinder = lazy(() => import("./Pages/fireflink-finder"));
const WebLocatorFinder = lazy(() => import("./Pages/weblocator-finder"));
const MobileLocatorFinder = lazy(() => import("./Pages/mobilelocator-finder"));
const SalesForceLocatorFinder = lazy(() =>
  import("./Pages/salesforce-locator-finder")
);
const MsDynamicsLocatorFinder = lazy(() =>
  import("./Pages/ms-dynamics-locator-finder")
);
const UserExperienceTesting = lazy(() =>
  import("./Pages/user-experience-testing")
);
const ScheduleDemo = lazy(() => import("./Pages/ScheduleDemo"));
const FireCloud = lazy(() => import("./Pages/fireflink-cloud"));
const FireAssests = lazy(() => import("./Pages/fireflink-assets"));
const NlpAssetsPage = lazy(() => import("./Pages/NlpAssetsPage"));
const ThankYou = lazy(() => import("./Pages/thank-you"));
const ThankYouScheduleDemoPage = lazy(() =>
  import("./Pages/ThankYouScheduleDemoPage")
);
const ThankYouContactPage = lazy(() => import("./Pages/ThankYouContactPage"));

const WhichIsBetterBlog = lazy(() => import("./Pages/blogs/WhichIsBetter"));
const EcommerceDomain = lazy(() =>
  import("./Pages/case-study/e-commerceDomain")
);
const EdTechDomain = lazy(() => import("./Pages/case-study/ed-techDomain"));
const HealthCareDomain = lazy(() =>
  import("./Pages/case-study/healthCareDomain")
);
const InsuranceDomain = lazy(() =>
  import("./Pages/case-study/insuranceDomain")
);
const MediaAndEntertainmentDomain = lazy(() =>
  import("./Pages/case-study/mediaAndEntertainmentDomain")
);

const TradingDomain = lazy(() => import("./Pages/case-study/tradingDomain"));
const SonyDomain = lazy(() => import("./Pages/case-study/sonyDomain"));
const VideoTutorials = lazy(() => import("./Pages/VideoTutorials"));
const Documentation = lazy(() => import("./Pages/Documentation"));
const SignUp = lazy(() => import("./Pages/Docs/Registration/SignUp"));
const SignInDoc = lazy(() => import("./Pages/Docs/Registration/SignIn"));
const ForgotPasswordDoc = lazy(() =>
  import("./Pages/Docs/Registration/ForgotPassword")
);
const AddUserToLicenseDoc = lazy(() =>
  import("./Pages/Docs/Registration/AddUserToLicense")
);
const ProjectsDoc = lazy(() =>
  import("./Pages/Docs/ProjectsMenu/AllProjectsLevel/Projects")
);
const RolesDoc = lazy(() =>
  import("./Pages/Docs/ProjectsMenu/AllProjectsLevel/Roles")
);
const UsersDoc = lazy(() =>
  import("./Pages/Docs/ProjectsMenu/AllProjectsLevel/Users")
);
const ProjectConfigurationDoc = lazy(() =>
  import(
    "./Pages/Docs/ProjectsMenu/IndividualProjectLevel/ProjectConfiguration"
  )
);
const IndividualRolesDoc = lazy(() =>
  import("./Pages/Docs/ProjectsMenu/IndividualProjectLevel/IndividualRoles")
);
const IndividualUsersDoc = lazy(() =>
  import("./Pages/Docs/ProjectsMenu/IndividualProjectLevel/IndividualUsers")
);
const ProgramElementsDoc = lazy(() =>
  import("./Pages/Docs/Repository/ProgramElements")
);
const ProjectElementsDoc = lazy(() =>
  import("./Pages/Docs/Repository/ProjectElements")
);
const SharedElementsDoc = lazy(() =>
  import("./Pages/Docs/Repository/SharedElements")
);
const StepGroupsDoc = lazy(() => import("./Pages/Docs/Repository/StepGroups"));
const AutomationTestStepsDoc = lazy(() =>
  import("./Pages/Docs/TestDevelopment/AutomationTestSteps")
);
const ScriptLevelPrePostCondition = lazy(() =>
  import("./Pages/Docs/TestDevelopment/PrePostConditions/ScriptLevel")
);
const AutomationTestScriptsDoc = lazy(() =>
  import("./Pages/Docs/TestDevelopment/AutomationTestScript")
);
const MaptoJira = lazy(() => import("./Pages/Docs/TestDevelopment/MaptoJira"));
const DataProviderDoc = lazy(() =>
  import("./Pages/Docs/TestDevelopment/DataProvider")
);
const DependsOnScriptDoc = lazy(() =>
  import("./Pages/Docs/TestDevelopment/DependsOnScript")
);
const ExecutionDashboardDoc = lazy(() =>
  import("./Pages/Docs/TestDevelopment/ExecutionDashboard")
);
const ManualTestCasesDoc = lazy(() =>
  import("./Pages/Docs/TestDevelopment/ManualTestCases")
);
const VariablesDoc = lazy(() =>
  import("./Pages/Docs/TestDevelopment/Variables")
);
const QuickRunSettingsDoc = lazy(() =>
  import("./Pages/Docs/TestDevelopment/QuickRunSettings")
);
const TestDevelopmentIntroductionDoc = lazy(() =>
  import("./Pages/Docs/TestDevelopment/IntroTestDevelopment")
);
const RestApiIntroductionDoc = lazy(() =>
  import("./Pages/Docs/TestDevelopment/WebServices/RestApiIntroduction")
);
const WebServiceIntroductionDoc = lazy(() =>
  import("./Pages/Docs/TestDevelopment/WebServices/WebServiceIntroduction")
);
const RequestInRestApiDoc = lazy(() =>
  import("./Pages/Docs/TestDevelopment/WebServices/RequestInRestApi")
);
const ResponseInRestApiDoc = lazy(() =>
  import("./Pages/Docs/TestDevelopment/WebServices/ResponseInRestApi")
);
const Curl = lazy(() =>
  import("./Pages/Docs/TestDevelopment/WebServices/Curl")
);
const SnippetsDoc = lazy(() =>
  import("./Pages/Docs/TestDevelopment/WebServices/Snippets")
);
const ExecutionIntroductionDoc = lazy(() =>
  import("./Pages/Docs/Execution/ExecutionIntroduction")
);

const VideoSample = lazy(() =>
  import("./Pages/videos/Registration/VideoSample")
);
const CaptureElements = lazy(() =>
  import("./Pages/videos/FireFlinkFinder/CaptureElements")
);
const CaptureMobElements = lazy(() =>
  import("./Pages/videos/FireFlinkFinder/CaptureMobElements")
);
const ManangeManualTestCase = lazy(() =>
  import("./Pages/videos/TestDevelopment/ManangeManualTestCase")
);
const CreateAutomationTestScript = lazy(() =>
  import("./Pages/videos/TestDevelopment/CreateAutomationTestScript")
);
const ApiTestAutomation = lazy(() =>
  import("./Pages/videos/TestDevelopment/ApiTestAutomation")
);
const RunAutomationScript = lazy(() =>
  import("./Pages/videos/TestDevelopment/RunAutomationScript")
);
const AutomationScriptDependency = lazy(() =>
  import("./Pages/videos/TestDevelopment/AutomationScriptDependency")
);
const MobileAppTestAutomation = lazy(() =>
  import("./Pages/videos/TestDevelopment/MobileAppTestAutomation")
);
const QuickRunSettingSetUp = lazy(() =>
  import("./Pages/videos/TestDevelopment/QuickRunSettingsSetUp")
);
const PrepostConditions = lazy(() =>
  import("./Pages/videos/TestDevelopment/PrePostConditions")
);
const TestDataProvider = lazy(() =>
  import("./Pages/videos/TestDevelopment/TestDataProvider")
);
const ManagingVariables = lazy(() =>
  import("./Pages/videos/TestDevelopment/Variables")
);
const DynamicUiElements = lazy(() =>
  import("./Pages/videos/TestDevelopment/DynamicUiElements")
);
const CreateAutomationTestSuites = lazy(() =>
  import("./Pages/videos/CreateAutomationTestSuites")
);
const ExecutionLogs = lazy(() =>
  import("./Pages/videos/TestDevelopment/ExecutionLogs")
);
const RunAutomationSuites = lazy(() =>
  import("./Pages/videos/RunAutomationSuites")
);
const CreateStepGroups = lazy(() =>
  import("./Pages/videos/Repository/CreateStepGroups")
);
const DynamicElements = lazy(() =>
  import("./Pages/videos/Repository/DynamicElements")
);
const SharedElements = lazy(() =>
  import("./Pages/videos/Repository/SharedElements")
);
const StepGroupParameterization = lazy(() =>
  import("./Pages/videos/Repository/StepGroupParameterization")
);
const DefectManagementAnalysis = lazy(() =>
  import("./Pages/videos/DefectAnalysis/DefectManagement")
);

const Routes = lazy(() => import("./Pages/routes"));
const ReleaseNotesVesions = lazy(() => import("./Pages/ReleaseNotesVesions"));
const ModuleLevel = lazy(() =>
  import("./Pages/Docs/TestDevelopment/PrePostConditions/ModuleLevel")
);
const RootModule = lazy(() =>
  import("./Pages/Docs/TestDevelopment/PrePostConditions/RootModule")
);
const ModuleResult = lazy(() =>
  import("./Pages/Docs/TestDevelopment/Result/ModuleResult")
);
const ScriptResult = lazy(() =>
  import("./Pages/Docs/TestDevelopment/Result/ScriptResult")
);
const AutomationStepResult = lazy(() =>
  import("./Pages/Docs/TestDevelopment/Result/AutomationStepResult")
);
const ExecutionDashboard = lazy(() =>
  import("./Pages/Docs/Execution/ExecutionDashboard")
);
const ScheduleInstance = lazy(() =>
  import("./Pages/Docs/Execution/ScheduleInstance")
);
const TestData = lazy(() => import("./Pages/Docs/TestData/TestData"));
const LicenseManagement = lazy(() =>
  import("./Pages/Docs/LicenseManagement/LicenseManagement")
);
const DefectsManagement = lazy(() =>
  import("./Pages/Docs/Defects/DefectsManagement")
);
const Dashboard = lazy(() => import("./Pages/Docs/Dashboard/Dashboard"));
const EmailConfiguration = lazy(() =>
  import("./Pages/Docs/Configuration/EmailConfiguration")
);
const MemoryConfiguration = lazy(() =>
  import("./Pages/Docs/Configuration/MemoryConfiguration")
);
const ResultConfiguration = lazy(() =>
  import("./Pages/Docs/Configuration/ResultConfiguration")
);
const DefectManagementConfig = lazy(() =>
  import("./Pages/Docs/Configuration/DefectManagementConfig")
);
const Labels = lazy(() => import("./Pages/Docs/Configuration/Labels"));
const TimeZone = lazy(() => import("./Pages/Docs/Configuration/TimeZone"));
const AutomationSuiteAnalytics = lazy(() =>
  import("./Pages/Docs/Analytics/AutomationSuiteAnalytics")
);
const DefectAnalyticsDoc = lazy(() =>
  import("./Pages/Docs/Analytics/DefectAnalytics")
);
const ScreenShotConfiguration = lazy(() =>
  import("./Pages/Docs/Configuration/RunConfiguration/ScreenShotConfiguration")
);
const WaitConfiguration = lazy(() =>
  import("./Pages/Docs/Configuration/RunConfiguration/WaitConfiguration")
);
const EnvironmentConfiguration = lazy(() =>
  import("./Pages/Docs/Configuration/RunConfiguration/EnvironmentConfiguration")
);
const ManualSuiteAnalytics = lazy(() =>
  import("./Pages/Docs/Analytics/ManualSuiteAnalytics")
);
const Slack = lazy(() =>
  import("./Pages/Docs/Configuration/ThirdPartyTools/Slack")
);
const BrowserStack = lazy(() =>
  import("./Pages/Docs/Configuration/ThirdPartyTools/BrowserStack")
);
const Github = lazy(() =>
  import("./Pages/Docs/Configuration/ThirdPartyTools/Github")
);
const Azure = lazy(() =>
  import("./Pages/Docs/Configuration/ThirdPartyTools/Azure")
);
const BitBucket = lazy(() =>
  import("./Pages/Docs/Configuration/ThirdPartyTools/Bitbucket")
);

const Gitlab = lazy(() =>
  import("./Pages/Docs/Configuration/ThirdPartyTools/Gitlab")
);
const DefectDetailsTemplate = lazy(() =>
  import("./Pages/Docs/Configuration/Templates/DefectDetailsTemplate")
);
const DefectLifeCycleTemplate = lazy(() =>
  import("./Pages/Docs/Configuration/Templates/DefectLifeCycleTemplate")
);
const Jenkins = lazy(() =>
  import("./Pages/Docs/Configuration/ThirdPartyTools/Jenkins")
);
const Jira = lazy(() =>
  import("./Pages/Docs/Configuration/ThirdPartyTools/Jira")
);
const ManualTestCaseTemplate = lazy(() =>
  import("./Pages/Docs/Configuration/Templates/ManualTestCaseTemplate")
);
const Lambda = lazy(() =>
  import("./Pages/Docs/Configuration/ThirdPartyTools/Lambda")
);
const SauceLab = lazy(() =>
  import("./Pages/Docs/Configuration/ThirdPartyTools/SauseLab")
);
const ImportExport = lazy(() =>
  import("./Pages/Docs/Configuration/ImportExport")
);

// const ReleaseNotesNine = lazy(() =>
//   import("./components/release-notes/ReleaseNotesNine")
// );
// const ReleaseNotesOne = lazy(() =>
//   import("./components/release-notes/ReleaseNotesOne")
// );
// const ReleaseNotesTwo = lazy(() =>
//   import("./components/release-notes/ReleaseNotesTwo")
// );
// const ReleaseNotesThree = lazy(() =>
//   import("./components/release-notes/ReleaseNotesThree")
// );
// const ReleaseNotesFour = lazy(() =>
//   import("./components/release-notes/ReleaseNotesFour")
// );
// const ReleaseNotesFive = lazy(() =>
//   import("./components/release-notes/ReleaseNotesFive")
// );
// const ReleaseNotesSix = lazy(() =>
//   import("./components/release-notes/ReleaseNotesSix")
// );
// const ReleaseNotesSeven = lazy(() =>
//   import("./components/release-notes/ReleaseNotesSeven")
// );
// const ReleaseNotesEight = lazy(() =>
//   import("./components/release-notes/ReleaseNotesEight")
// );
// const ReleaseNotesTwoPointOne = lazy(() =>
//   import("./components/release-notes/ReleaseNotesTwoPointOne")
// );

const TwoPointThree = lazy(() =>
  import("./components/release-notes/TwoPointThree")
);
const TwoPointTwo = lazy(() =>
  import("./components/release-notes/TwoPointTwo")
);
const TwoPointOne = lazy(() =>
  import("./components/release-notes/TwoPointOne")
);
const TwoPointZero = lazy(() =>
  import("./components/release-notes/TwoPointZero")
);
const OnePointTwo = lazy(() =>
  import("./components/release-notes/OnePointTwo")
);
const OnePointOne = lazy(() =>
  import("./components/release-notes/OnePointOne")
);
const OnePointZero = lazy(() =>
  import("./components/release-notes/OnePointZero")
);
// const TwoPointZeroPointFive = lazy(() =>
//   import("./components/release-notes/TwoPointZeroPointFive")
// );
const CanonicalUrl = lazy(() => import("./components/Common/CanonicalUrl"));
const CookieAccept = lazy(() => import("./components/Common/CookieAccept"));
const ChatbotComponent = lazy(() => import("./components/ChatBot/ChatBot"));
const SapAutomation = lazy(() => import("./Pages/SapAutomation"));
const SalesforceAutomation = lazy(() => import("./Pages/SalesforceAutomation"));
const MsDynamicsAutomation = lazy(() => import("./Pages/MsDynamicsAutomation"));
const PeopleSoftAutomation = lazy(() => import("./Pages/PeopleSoftAutomation"));
const WorkDayAutomation = lazy(() => import("./Pages/WorkDayAutomation"));
const ServiceNowAutomation = lazy(() => import("./Pages/ServiceNowAutomation"));
const OracleAutomation = lazy(() => import("./Pages/OracleAutomation"));
const AppiumAlternative = lazy(() => import("./Pages/AppiumAlternative"));
const KatalonAlternative = lazy(() => import("./Pages/KatalonAlternative"));
const TestSigmaAlternative = lazy(() => import("./Pages/TestSigmaAlternative"));
const LeapworkAlternative = lazy(() => import("./Pages/LeapworkAlternative"));
const TestRigorAlternative = lazy(() => import("./Pages/TestRigorAlternative"));
const ToscaAlternative = lazy(() => import("./Pages/ToscaAlternative"));
const TestimAlternative = lazy(() => import("./Pages/TestimAlternative"));
const SeleniumAlternative = lazy(() => import("./Pages/SeleniumAlternative"));
const PreRequites = lazy(() => import("./Pages/PreRequites"));
const PreReqOnPrem = lazy(() => import("./Pages/PreReqOnPrem"));
const TestCompleteAlternative = lazy(() =>
  import("./Pages/TestCompleteAlternative")
);

const ConcurrentExeCalc = lazy(() => import("./Pages/ConcurrentExeCalc"));

const AcadamyLanding = lazy(() => import("./Pages/Acadamy/AcadamyLanding"));
// const SkilledTrainerPage = lazy(() => import("./Pages/Acadamy/SkilledTrainerPage"));
const TrainingPlanPage = lazy(() => import("./Pages/Acadamy/TrainingPlanPage"));
const PrivacyPolicyPage = lazy(() => import("./Pages/PrivacyPolicyPage"));

const Webinar = lazy(() => import("./Pages/Webinar/WebinarLanding"));

const ThankYouWeb = lazy(() => import("./components/Webinar/ThankYouWebinar"));
const PartnerWithFireFlink = lazy(() =>
  import("./components/PartnerWithFireFlink/Partner")
);
// const TRACKING_ID = "G-BBYTL2JZSP";
// ReactGA.initialize(TRACKING_ID);

const App = (props) => {
  // componentDidMount() {
  //   this.props.
  // }
  useEffect(() => {
    props.hideLoader();
  }, [props]);

  return (
    <>
      <Router>
        {/* <HelmetProvider> */}

        <Suspense fallback={<div></div>}>
          <CanonicalUrl />
          <CookieAccept />
          <Switch>
            <ScrollToTopRoute exact={true} path={"/"} component={HomeLanding} />
            <ScrollToTopRoute
              exact={true}
              path={"/fireflink-academy"}
              component={AcadamyLanding}
            />
            <ScrollToTopRoute
              exact
              path="/product-features"
              component={ProductFeatures}
            />
            <ScrollToTopRoute exact path="/webinars" component={Webinar} />
            <ScrollToTopRoute
              exact
              path="/thank-you-for-registering"
              component={ThankYouWeb}
            />
            <ScrollToTopRoute
              exact
              path="/email-web-view"
              component={PartnerWithFireFlink}
            />
            <ScrollToTopRoute exact path="/blogs" component={BlogHome} />
            {/* <ScrollToTopRoute exact path="/blogs-home" component={BlogHome} /> */}
            <ScrollToTopRoute
              exact
              path="/blogs/user-acceptance-testing-guide"
              component={UserAcceptanceTestingBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/software-testing-move-forward-without-coding"
              component={MovingForwardWithoutTestingBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/roi-benefit-from-scriptless-test-automation"
              component={DoesRoiBenefitBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/which-is-better-scriptless-testing-or-scripted-testing"
              component={WhichIsBetterBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/scalable-on-demand-testing"
              component={ScalableOnDemandTesting}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/streamline-ui-reskin-challenges"
              component={ImpactOfUi}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/streamlining-debugging"
              component={StreamliningDebugging}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/enhancing-test-automation"
              component={EnchancingTestAutomation}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/step-tagging"
              component={StepTagging}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/simplifying-test-automation"
              component={SimplifyingTestAutomation}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/seamless-test-automation-integration"
              component={ElevatingTestAutomation}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/simplifying-regression-testing"
              component={SimplifyingRegressionTesting}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/Simplifying-api-testing"
              component={SimplifyingApiTesting}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/mobile-testing-simplified"
              component={MobileTestingSimplified}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/challenges-faced-by-qa"
              component={ChallengesFacedByQa}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/power-of-no-code"
              component={PowerOfNoCode}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/essentials-of-end-to-end-testing"
              component={EssentialsOfEndtoEndTesting}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/mastering-unit-testing"
              component={MasteringUnitTesting}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/simplifying-end-to-end-testing"
              component={SimplifyingEndtoEndTesting}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/end-to-end-testing"
              component={EndtoEndTesting}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/mastering-end-to-end-testing"
              component={MasteringEndtoEndTesting}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/optimising-testing"
              component={OptimisingTesting}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/common-unit-testing-pitfalls"
              component={CommonTestingPitfalls}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/crucial-role-of-unit-testing"
              component={CrucialRoleOfUnitTesting}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/enhancing-automation-testing"
              component={EnhancingAutomationTesting}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/qa-automation"
              component={qaAutomation}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/qa-automation-selecting-right-tools"
              component={selectingRightToolsQa}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/mastering-debugging"
              component={MasteringDebugging}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/software-testing-life-cycle"
              component={SoftwareTestingLifeCycle}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/software-testing-basics"
              component={SoftwareTestingBasics}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/comparative-analysis"
              component={ComparativeAnalysis}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/best-practices-for-writing-robust-tests"
              component={BestPracticesRobustTesing}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/how-to-migrate-from-manual-testing-to-automation-testing"
              component={HowtoMigrate}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/quality-assurance-solutions-for-seamless-software-development"
              component={QualityAssuranceSolutions}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/qa-automation-emerging-trends-and-innovations"
              component={QaAutomationEmergingTrends}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/exploratory-testing"
              component={ExploratoryTesting}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/regression-testing"
              component={RegressionTesting}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/unit-testing"
              component={UnitTesting}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/unit-testing-in-CI-CD-environment"
              component={UnitTestingInCICDEnv}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/qa-testing-methods"
              component={QaTestingMethods}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/integrating-unit-tests-into-CI/CD-pipelines"
              component={IntegrationUnitTesting}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/introduction-to-test-automation"
              component={IntroToTestAutomation}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/automation-testing-best-practice"
              component={AutomationTestingBestPracticeBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/qa-testing-procedures"
              component={QaTestingProcedures}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/mobile-app-testing"
              component={MobileAppTesting}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/emulators-and-simulators"
              component={EmulatorsAndSimulators}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/integrating-automation-testing"
              component={IntegratingAutomationTestingBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/best-end-to-end-testing"
              component={BestEndToEndTesting}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/cloud-testing"
              component={CloudTestingBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/acceptance-testing"
              component={AcceptanceTestingBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/test-cases-and-test-suites"
              component={TestCasesTestSuites}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/what-is-end-to-end-testing"
              component={WhatIsEndToEnd}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/role-and-importance-of-automation-testing"
              component={CommonChallenges}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/guidelines-and-recommendations-for-writing-effective-and-reliable-automated-tests"
              component={GuideLinesRec}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/quality-assurance"
              component={QualityAssurance}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/top-android-automation-testing-tools"
              component={TopAndroidAutomationBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/fireflink-why-it-is-better-to-record-and-playback"
              component={FireFlinkWhyItIsBetter}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/web-performance-testing"
              component={WebPerformance}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/step-by-step-guide-to-develop-test-scripts-for-software-testing"
              component={StepByStepGuideBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/key-metrics-to-measure-efficiency-of-automation-testing"
              component={KeyMetricsAutomationTestingBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/web-performance-testing-tool"
              component={WebPerformanceTestingBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/web-automation-framework"
              component={WebAutomationFrameworkBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/non-functional-testing"
              component={NonFunctionalTesting}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/software-testing-system-testing"
              component={SystemTestingBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/top-15-automation-testing-tool-2024"
              component={TopAutomationToolBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/methods-and-tools-for-running-test-scripts-in-various-testing-environments"
              component={MethodAndTool}
            />

            <ScrollToTopRoute
              exact
              path="/blogs/how-are-front-end-and-back-end-testing-different"
              component={FrontEndBackEndTestingBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/differentiating-manual-test-scripts-automation-test-scripts-and-hybrid-test-scripts"
              component={ManualAutomationTestScriptDifferentBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/ios-automation-testing-tool"
              component={IosAutomationToolBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/front-end-testing"
              component={FrontEndTestingBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/best-practices-and-strategies-for-writing-clear-and-maintainable-test-scripts"
              component={TestScriptBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/comprehensive-guide-to-end-to-end-testing-on-fireFlink"
              component={ComprehensiveEndToEndTesingBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/definition-and-overview-of-test-scripts-in-software-testing"
              component={OverviewTestScriptBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/different-types-of-software-testing"
              component={DifferentTypesSoftwareTestingBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/elements-and-structure-of-test-script"
              component={ElementsAndStructureOfTestScriptBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/importance-of-regular-updates-and-maintenance-of-test-scripts"
              component={UpdatesAndTransformationOfTestsciptBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/exploring-the-top-scripting-languages-for-test-automation"
              component={ExploringTopScriptingLanguagesBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/comparing-the-pros-and-cons-of-manual-testing-and-automation-testing-in-product-development"
              component={ProsConsManualAutmationTestingBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/overview-of-the-importance-and-purpose-of-testing-in-product-development"
              component={ImportancePurposeOfTestingProductDevBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/leveraging-test-scripts-in-ci-cd-pipelines-for-automated-testing"
              component={LeverageTestScriptCiCdBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/exploring-different-testing-phases-including-unit-testing-integration-testing-system-testing-and-acceptance-testing"
              component={ExploringDifferentTestingPhasesBlog}
            />
            <ScrollToTopRoute
              exact
              path="/blogs/regression-testing-ensure-no-impact-existing-functionality"
              component={ImplementingRegressingTestingBlog}
            />
            <ScrollToTopRoute
              exact
              path="/case-studies"
              component={CaseStudy}
            />
            <ScrollToTopRoute
              exact
              // path='/:url([a-z/]*[A-Z]+[a-z/]*)/'
              path="/fireflink-platform"
              component={FirePlatform}
              //   render={props => {
              //     const path = "/fireflink-platform"
              //     return <Redirect to={`${path.toLowerCase()}`} />
              // }}
            />
            {/* <ScrollToTopRoute
              exact
              path="/HomeSecurity"
              component={homeSecurity}
            /> */}
            <ScrollToTopRoute exact path="/contactus" component={ContactUs} />
            <ScrollToTopRoute
              exact
              path="/fireflink-platform-pricing"
              component={FireflinkPlatformPrcing}
            />
            <ScrollToTopRoute
              exact
              path="/fireflink-finder"
              component={FireflinkFinder}
            />
            <ScrollToTopRoute
              exact
              path="/fireflink-finder/web-locator-finder"
              component={WebLocatorFinder}
            />
            <ScrollToTopRoute
              exact
              path="/fireflink-finder/mobile-app-locator-finder"
              component={MobileLocatorFinder}
            />
            <ScrollToTopRoute
              exact
              path="/fireflink-finder/salesforce-locator-finder"
              component={SalesForceLocatorFinder}
            />
            <ScrollToTopRoute
              exact
              path="/fireflink-finder/msdynamics-locator-finder"
              component={MsDynamicsLocatorFinder}
            />
            <ScrollToTopRoute
              exact
              path="/schedule-demo"
              component={ScheduleDemo}
            />
            <ScrollToTopRoute
              exact
              path={`/fireflink-platform/web-automation-testing`}
              component={HomeCloud}
            />
            <ScrollToTopRoute
              exact
              path="/fireflink-platform/mobile-app-automation-testing"
              component={MobileappTest}
            />
            <ScrollToTopRoute
              exact
              path="/fireflink-platform/api-automation-testing"
              component={ApiTest}
            />
            <ScrollToTopRoute
              exact
              path="/fireflink-platform/user-experience-testing"
              component={UserExperienceTesting}
            />
            <ScrollToTopRoute
              exact
              path="/fireflink-platform/defect-management"
              component={DefectManagement}
            />
            <ScrollToTopRoute
              exact
              path="/fireflink-platform/manual-test-case-management"
              component={ManualTestCaseManagement}
            />
            <ScrollToTopRoute
              exact
              path="/fireflink-platform/visual-testing"
              component={UxTesting}
            />
            <ScrollToTopRoute
              exact
              path="/fireflink-platform/ui-performance-testing"
              component={UiTesting}
            />
            <ScrollToTopRoute
              exact
              path="/sales-force-testing"
              component={SalesforceTesting}
            />
            <ScrollToTopRoute
              exact
              path="/desktop-app-testing"
              component={DesktopAppTesting}
            />
            <ScrollToTopRoute exact path="/Faq" component={Faq} />
            <ScrollToTopRoute path="/signin" component={SignIn} exact />
            <ScrollToTopRoute path="/signup" component={SignUpMain} exact />
            <ScrollToTopRoute
              path="/registration-success"
              component={RegistrationSuccessfulPage}
              exact
            />
            <ScrollToTopRoute
              path="/change-phone-number"
              component={ChangePhoneNumber}
              exact
            />
            <ScrollToTopRoute
              path="/otp-verification"
              component={VerificationCode}
              exact
            />
            <ScrollToTopRoute
              path="/activation-success"
              component={ActivationSuccess}
              exact
            />
            <ScrollToTopRoute
              path="/forgot-password"
              component={ForgotPassword}
              exact
            />
            <ScrollToTopRoute
              path="/change-password"
              component={ChangePassword}
              exact
            />
            <ScrollToTopRoute
              path="/reset-link-sent-success"
              component={ResetLinkSent}
              exact
            />
            <ScrollToTopRoute
              path="/password-changed-success"
              component={PasswordChangedSuccess}
              exact
            />
            <ScrollToTopRoute
              path="/activation-link-expired"
              component={ActivationLinkExpired}
              exact
            />
            <ScrollToTopRoute
              path="/activation-link-sent-successfully"
              component={ActivationLinkSentSuccess}
              exact
            />
            <ScrollToTopRoute
              path="/activation-warning"
              component={WarningActivation}
              exact
            />
            <ScrollToTopRoute
              path="/user-activated"
              component={UserActivated}
              exact
            />
            <ScrollToTopRoute
              path="/user-already-activated"
              component={UserAlreadyActivated}
              exact
            />
            <ScrollToTopRoute
              path="/setpassword-link-expired"
              component={SetPasswordLinkExpired}
              exact
            />
            <ScrollToTopRoute
              path="/active-user"
              component={ActiveUser}
              exact
            />
            {/* <ScrollToTopRoute
              exact
              path="/fireflink-crowd"
              component={FireCrowd}
            /> */}
            <ScrollToTopRoute
              exact
              path="/fireflink-products"
              component={ProductsPage}
            />
            <ScrollToTopRoute
              exact
              path="/fireflink-cloud"
              component={FireCloud}
            />
            <ScrollToTopRoute
              exact
              path="/fireflink-assets"
              component={FireAssests}
            />
            <ScrollToTopRoute
              exact
              path="/fireflink-assets-new"
              component={FireflinkAssetNew}
            />
            <ScrollToTopRoute exact path="/nlp's" component={NlpAssetsPage} />
            <ScrollToTopRoute
              exact
              path="/elements"
              component={ElementsAssetsPage}
            />
            <ScrollToTopRoute
              exact
              path="/step-groups"
              component={StepGroupAssetPage}
            />
            <ScrollToTopRoute exact path="/nlp's-list" component={ExcelPage} />
            <ScrollToTopRoute
              exact
              path="/nlp-description"
              component={AssetDescriptionPage}
            />
            <ScrollToTopRoute
              exact
              path="/elements-list"
              component={ListOfElements}
            />
            <ScrollToTopRoute
              exact
              path="/stepgroup-list"
              component={ListOfStepGroup}
            />
            <ScrollToTopRoute
              exact
              path="/elements-description"
              component={ElementsDescriptionPage}
            />
            <ScrollToTopRoute
              exact
              path="/step-group-description"
              component={StepGroupDescriptionPage}
            />
            <ScrollToTopRoute
              exact
              path="/assets-search"
              component={AssetSearchResultPage}
            />
            <ScrollToTopRoute exact path="/thank-you" component={ThankYou} />
            <ScrollToTopRoute
              exact
              path="/thank-you-for-scheduling-demo"
              component={ThankYouScheduleDemoPage}
            />
            <ScrollToTopRoute
              exact
              path="/thank-you-for-contact-us"
              component={ThankYouContactPage}
            />
            <ScrollToTopRoute
              exact
              path="/case-studies/ecommerce"
              component={EcommerceDomain}
            />
            <ScrollToTopRoute
              exact
              path="/case-studies/edtech"
              component={EdTechDomain}
            />
            <ScrollToTopRoute
              exact
              path="/case-studies/trading"
              component={TradingDomain}
            />
            <ScrollToTopRoute
              exact
              path="/case-studies/healthcare"
              component={HealthCareDomain}
            />
            <ScrollToTopRoute
              exact
              path="/case-studies/media-and-entertainment"
              component={MediaAndEntertainmentDomain}
            />
            <ScrollToTopRoute
              exact
              path="/case-studies/insurance"
              component={InsuranceDomain}
            />
            <ScrollToTopRoute
              exact
              path="/case-studies/sony"
              component={SonyDomain}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial"
              component={VideoTutorials}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial/registration/sign-up-and-create-a-project"
              component={VideoSample}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial/fireflink-finder/capture-and-store-web-ui-elements"
              component={CaptureElements}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial/fireflink-finder/capture-and-store-mobile-ui-elements"
              component={CaptureMobElements}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial/test-development/manage-manual-test-case"
              component={ManangeManualTestCase}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial/test-development/create-a-web-automation-script"
              component={CreateAutomationTestScript}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial/execution/create-automation-test-suites"
              component={CreateAutomationTestSuites}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial/execution/run-automation-suites"
              component={RunAutomationSuites}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial/test-development/run-automation-script"
              component={RunAutomationScript}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial/test-development/create-an-api-automation-script"
              component={ApiTestAutomation}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial/test-development/creating-script-dependencies"
              component={AutomationScriptDependency}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial/test-development/execution-logs-within-fireflink"
              component={ExecutionLogs}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial/test-development/create-a-mobile-automation-script"
              component={MobileAppTestAutomation}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial/test-development/setup-of-quick-run-settings"
              component={QuickRunSettingSetUp}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial/test-development/managing-prepost-conditions"
              component={PrepostConditions}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial/test-development/test-data-and-data-providers"
              component={TestDataProvider}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial/test-development/managing-variables"
              component={ManagingVariables}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial/test-development/utilizing-dynamic-ui-elements-in-a-script"
              component={DynamicUiElements}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial/repository/creating-step-groups"
              component={CreateStepGroups}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial/repository/dynamic-elements-with-in-repository"
              component={DynamicElements}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial/repository/shared-elements-within-fireflink"
              component={SharedElements}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial/repository/step-group-parameterization-and-nesting"
              component={StepGroupParameterization}
            />
            <ScrollToTopRoute
              exact
              path="/video-tutorial/defect-analysis/defect-management"
              component={DefectManagementAnalysis}
            />
            {/* <ScrollToTopRoute
              exact
              path="/release-notes/version/1/6/3"
              component={ReleaseNotesVesions}
            />
            <ScrollToTopRoute
              exact
              path="/release-notes/version/1/6/2"
              component={ReleaseNotesNine}
            />
            <ScrollToTopRoute
              exact
              path="/release-notes/version/1/6/1"
              component={ReleaseNotesEight}
            />
            <ScrollToTopRoute
              exact
              path="/release-notes/version/1/6/0"
              component={ReleaseNotesSeven}
            />
            <ScrollToTopRoute
              exact
              path="/release-notes/version/1/5/0"
              component={ReleaseNotesSix}
            />
            <ScrollToTopRoute
              exact
              path="/release-notes/version/1/4/0"
              component={ReleaseNotesFive}
            />
            <ScrollToTopRoute
              exact
              path="/release-notes/version/1/3/0"
              component={ReleaseNotesFour}
            />
            <ScrollToTopRoute
              exact
              path="/release-notes/version/1/2/0"
              component={ReleaseNotesThree}
            />
            <ScrollToTopRoute
              exact
              path="/release-notes/version/1/1/0"
              component={ReleaseNotesTwo}
            />
            <ScrollToTopRoute
              exact
              path="/release-notes/version/1/0/0"
              component={ReleaseNotesOne}
            />
            <ScrollToTopRoute
              exact
              path="/release-notes/version/1/7/0"
              component={ReleaseNotesTwoPointOne}
            /> */}
            <ScrollToTopRoute
              exact
              path="/release-notes/version/1/2"
              component={OnePointTwo}
            />
            <ScrollToTopRoute
              exact
              path="/release-notes/latest-version"
              component={TwoPointThree}
            />
            <ScrollToTopRoute
              exact
              path="/release-notes/version/2/2"
              component={TwoPointTwo}
            />
            <ScrollToTopRoute
              exact
              path="/release-notes/version/2/1"
              component={TwoPointOne}
            />
            <ScrollToTopRoute
              exact
              path="/release-notes/version/2/0"
              component={TwoPointZero}
            />
            <ScrollToTopRoute
              exact
              path="/release-notes/version/1/1"
              component={OnePointOne}
            />
            <ScrollToTopRoute
              exact
              path="/release-notes/version/1/0"
              component={OnePointZero}
            />
            {/* <ScrollToTopRoute
              exact
              path="/release-notes/latest-version/"
              component={TwoPointZeroPointFive}
            /> */}
            <ScrollToTopRoute
              exact
              path="/documentation"
              component={Documentation}
            />
            <ScrollToTopRoute
              exact
              path="/docs/user-management/sign-up"
              component={SignUp}
            />
            <ScrollToTopRoute
              exact
              path="/docs/user-management/sign-in"
              component={SignInDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/user-management/forgot-password"
              component={ForgotPasswordDoc}
            />
            {/* <ScrollToTopRoute
            exact
            path="/docs/user-management/users"
            component={AddUserToLicenseDoc}
          /> */}
            <ScrollToTopRoute
              exact
              path="/docs/project-management/projects"
              component={ProjectsDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/project-management/roles"
              component={RolesDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/user-management/users"
              component={UsersDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/project-menu/individual-project-level/project-configuration"
              component={ProjectConfigurationDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/project-menu/individual-project-level/roles"
              component={IndividualRolesDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/project-menu/individual-project-level/users"
              component={IndividualUsersDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/repository/program-elements"
              component={ProgramElementsDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/repository/project-elements"
              component={ProjectElementsDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/repository/shared-elements"
              component={SharedElementsDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/repository/step-groups"
              component={StepGroupsDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-development/automation-test-steps"
              component={AutomationTestStepsDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-development/pre-post-condition/script-level"
              component={ScriptLevelPrePostCondition}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-development/pre-post-condition/module-level"
              component={ModuleLevel}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-development/pre-post-condition/root-level"
              component={RootModule}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-development/automation-test-scripts"
              component={AutomationTestScriptsDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-development/map-to-jira"
              component={MaptoJira}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-development/data-provider"
              component={DataProviderDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-development/depends-on-script"
              component={DependsOnScriptDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-development/execution-dashboard"
              component={ExecutionDashboardDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-development/manual-test-case"
              component={ManualTestCasesDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-development/variables"
              component={VariablesDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-development/quick-run-settings"
              component={QuickRunSettingsDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-development/introduction-to-test-development"
              component={TestDevelopmentIntroductionDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-development/web-services/introduction-to-rest-api"
              component={RestApiIntroductionDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-development/web-services/introduction-to-web-service"
              component={WebServiceIntroductionDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-development/web-services/request-in-rest-api"
              component={RequestInRestApiDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-development/web-services/response-in-rest-api"
              component={ResponseInRestApiDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-development/web-services/snippets"
              component={SnippetsDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-development/web-services/curl"
              component={Curl}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-development/result/module-result"
              component={ModuleResult}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-development/result/script-result"
              component={ScriptResult}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-development/result/step-result"
              component={AutomationStepResult}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-execution/execution-dashboard"
              component={ExecutionDashboard}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-execution/scheduled-instances"
              component={ScheduleInstance}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-execution/introduction-to-execution"
              component={ExecutionIntroductionDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/test-data"
              component={TestData}
            />
            <ScrollToTopRoute
              exact
              path="/docs/license-management"
              component={LicenseManagement}
            />
            <ScrollToTopRoute
              exact
              path="/docs/defects"
              component={DefectsManagement}
            />
            <ScrollToTopRoute
              exact
              path="/docs/dashboard"
              component={Dashboard}
            />
            <ScrollToTopRoute
              exact
              path="/docs/configuration/email-configuration"
              component={EmailConfiguration}
            />
            <ScrollToTopRoute
              exact
              path="/docs/configuration/memory-configuration"
              component={MemoryConfiguration}
            />
            <ScrollToTopRoute
              exact
              path="/docs/configuration/result-configuration"
              component={ResultConfiguration}
            />
            <ScrollToTopRoute
              exact
              path="/docs/configuration/defect-management"
              component={DefectManagementConfig}
            />
            <ScrollToTopRoute
              exact
              path="/docs/configuration/labels"
              component={Labels}
            />
            <ScrollToTopRoute
              exact
              path="/docs/configuration/time-zone"
              component={TimeZone}
            />
            <ScrollToTopRoute
              exact
              path="/docs/analytics/automation-suite-analytics"
              component={AutomationSuiteAnalytics}
            />

            <ScrollToTopRoute
              exact
              path="/docs/analytics/defect-analytics"
              component={DefectAnalyticsDoc}
            />
            <ScrollToTopRoute
              exact
              path="/docs/analytics/manual-suite-analytics"
              component={ManualSuiteAnalytics}
            />
            <ScrollToTopRoute
              exact
              path="/docs/configuration/integrations/slack"
              component={Slack}
            />
            <ScrollToTopRoute
              exact
              path="/docs/configuration/integrations/browser-stack"
              component={BrowserStack}
            />
            <ScrollToTopRoute
              exact
              path="/docs/configuration/integrations/github"
              component={Github}
            />
            <ScrollToTopRoute
              exact
              path="/docs/configuration/integrations/azure"
              component={Azure}
            />
            <ScrollToTopRoute
              exact
              path="/docs/configuration/integrations/bitbucket"
              component={BitBucket}
            />
            <ScrollToTopRoute
              exact
              path="/docs/configuration/integrations/gitlab"
              component={Gitlab}
            />
            <ScrollToTopRoute
              exact
              path="/docs/configuration/integrations/jenkins"
              component={Jenkins}
            />
            <ScrollToTopRoute
              exact
              path="/docs/configuration/integrations/jira"
              component={Jira}
            />
            <ScrollToTopRoute
              exact
              path="/docs/configuration/integrations/lambda-test"
              component={Lambda}
            />
            <ScrollToTopRoute
              exact
              path="/docs/configuration/integrations/sauce-labs"
              component={SauceLab}
            />
            <ScrollToTopRoute
              exact
              path="/docs/configuration/run-configuration/screenshot-configuration"
              component={ScreenShotConfiguration}
            />
            <ScrollToTopRoute
              exact
              path="/docs/configuration/run-configuration/wait-configuration"
              component={WaitConfiguration}
            />
            <ScrollToTopRoute
              exact
              path="/docs/configuration/run-configuration/environment-configuration"
              component={EnvironmentConfiguration}
            />
            <ScrollToTopRoute
              exact
              path="/docs/configuration/templates/defect-details-template"
              component={DefectDetailsTemplate}
            />
            <ScrollToTopRoute
              exact
              path="/docs/configuration/templates/defect-life-cycle-template"
              component={DefectLifeCycleTemplate}
            />
            <ScrollToTopRoute
              exact
              path="/docs/configuration/templates/manual-test-case-template"
              component={ManualTestCaseTemplate}
            />
            {/* <ScrollToTopRoute
              exact
              path="/docs/configuration/import-and-export"
              component={ImportExport}
            /> */}
            <ScrollToTopRoute
              exact
              path="/solution/sap-automation-testing"
              component={SapAutomation}
            />
            <ScrollToTopRoute
              exact
              path="/solution/salesforce-automation-testing"
              component={SalesforceAutomation}
            />
            <ScrollToTopRoute
              exact
              path="/solution/msdynamics-automation-testing"
              component={MsDynamicsAutomation}
            />
            <ScrollToTopRoute
              exact
              path="/solution/peoplesoft-automation-testing"
              component={PeopleSoftAutomation}
            />
            <ScrollToTopRoute
              exact
              path="/solution/workday-automation-testing"
              component={WorkDayAutomation}
            />
            <ScrollToTopRoute
              exact
              path="/solution/service-now-automation-testing"
              component={ServiceNowAutomation}
            />
            <ScrollToTopRoute
              exact
              path="/solution/oracle-automation-testing"
              component={OracleAutomation}
            />
            {/* <ScrollToTopRoute
              exact
              path="/appium-alternative"
              component={AppiumAlternative}
            />
            <ScrollToTopRoute
              exact
              path="/katalon-alternative"
              component={KatalonAlternative}
            />
            <ScrollToTopRoute
              exact
              path="/test-sigma-alternative"
              component={TestSigmaAlternative}
            />
            <ScrollToTopRoute
              exact
              path="/leap-work-alternative"
              component={LeapworkAlternative}
            />
            <ScrollToTopRoute
              exact
              path="/test-rigor-alternative"
              component={TestRigorAlternative}
            />
            <ScrollToTopRoute
              exact
              path="/tosca-alternative"
              component={ToscaAlternative}
            />
            <ScrollToTopRoute
              exact
              path="/testim-alternative"
              component={TestimAlternative}
            />
            <ScrollToTopRoute
              exact
              path="/selenium-alternative"
              component={SeleniumAlternative}
            /> */}
            {/* <ScrollToTopRoute
              exact
              path="/test-complete-alternative"
              component={TestCompleteAlternative}
            /> */}
            <ScrollToTopRoute
              exact
              path="/prerequisites/on-premise-version"
              component={PreRequites}
            />
            <ScrollToTopRoute
              exact
              path="/prerequisites/cloud-version"
              component={PreReqOnPrem}
            />
            <ScrollToTopRoute
              exact
              path="/prerequisites/safari-browser-automation-limitations"
              component={SafariBrowserLimitation}
            />
            <ScrollToTopRoute
              exact
              path="/concurrent-execution-calculator"
              component={ConcurrentExeCalc}
            />
            <ScrollToTopRoute
              exact
              path="/cookies-policy"
              component={DataPolicy}
            />
            {/* <ScrollToTopRoute
              exact
              path="/fireflink-academy/skilled-trainer"
              component={SkilledTrainerPage}
            /> */}
            <ScrollToTopRoute
              exact
              path="/fireflink-academy/training-plan"
              component={TrainingPlanPage}
            />
            <ScrollToTopRoute
              exact
              path="/privacy-policy"
              component={PrivacyPolicyPage}
            />
            {/* <ScrollToTopRoute exact path="/routes" component={Routes} /> */}
            <ScrollToTopRoute component={NotFound} />
          </Switch>
        </Suspense>
        <NotificationContainer />

        {/* </HelmetProvider> */}
      </Router>
    </>
  );
};

export default App;
