import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link, NavLink, useHistory, useNavigate } from "react-router-dom";
import Sticky from "react-stickynode";
import arrow from "../img/home/down-arrow.png";
import bluearrow from "../img/down-arrow-blue.svg";
import fireflinkwhite from "../../src/img/home/fireflink-white.svg";
import fireflinklogo from "../../src/img/home/fireflink.svg";
import acadamyLogo from "../img/acadamy/academyLogo.svg";
import useAnalyticsEventTracker from "../components/analytics/useAnalyticsEventTracker";
import Avatar from "@mui/material/Avatar";
import { signOutReq } from "../api/api_services";
import { verifyLicenseStatus } from "../../src/utils/license-utils";

import DropDownMenu from "./Common/DropDownMenu";
import SignInButton from "./Common/SignInButton";
import GetDemoNavButton from "./Common/GetDemoNavBtn";

import WebAutomation from "../img/home/menu/platform/web-automation-testing.svg";
import MobileAutomation from "../img/home/menu/platform/mobile-automation.svg";
import ApiAutomation from "../img/home/menu/platform/api-testing.svg";
import ManualTest from "../img/home/menu/platform/manual-test-case-management.svg";
import Defect from "../img/home/menu/platform/defect-management.svg";
import UiTest from "../img/home/menu/platform/ui-performance-testing.svg";
import VisualTesting from "../img/home/menu/platform/visual-testing.svg";
import MobileLocator from "../img/home/menu/finder/mobile-locator-finder.svg";
import MsLocator from "../img/home/menu/finder/ms-dynamic-locator.svg";
import SalesForceLocator from "../img/home/menu/finder/sales-force-locator.svg";
import WebLocatorFinder from "../img/home/menu/finder/web-locator.svg";
import DomainAssets from "../img/home/menu/assets/domain-assets.svg";
import TemplateStore from "../img/home/menu/assets/template-store-assets.svg";
import PlatformAssets from "../img/home/menu/assets/platform-assets.svg";
import CloudLive from "../img/home/menu/cloud/cloud-live.svg";
import CloudMobile from "../img/home/menu/cloud/cloud-mobile.svg";
import CloudWeb from "../img/home/menu/cloud/cloud-web.svg";

import MsDynamics from "../img/home/menu/solutions/ms.svg";
import Oracle from "../img/home/menu/solutions/oracle.svg";
import PeopleSoft from "../img/home/menu/solutions/people-soft.svg";
import SalesForce from "../img/home/menu/solutions/salesforce.svg";
import SapAutomation from "../img/home/menu/solutions/sap.svg";
import ServiceNow from "../img/home/menu/solutions/servicenow.svg";
import WorkDayAutomation from "../img/home/menu/solutions/workday-seeklogo.svg";

import Academy from "../img/home/menu/resources/academy.svg";
import Blogs from "../img/home/menu/resources/blog.svg";
import CaseStudy from "../img/home/menu/resources/case-studies.svg";
import Documentation from "../img/home/menu/resources/documentation.svg";
import PreRequites from "../img/home/menu/resources/prerequisites.svg";
import ReleaseNotes from "../img/home/menu/resources/release-notes.svg";
import Support from "../img/home/menu/resources/support.svg";
import VideoTutorials from "../img/home/menu/resources/video-tutorials.svg";

import OrangeDownarrow from "../img/home/menu/orange-down-arrow.svg";
import MessageList from "./ChatBot/MessageList";
import { openDeepLink } from "../utils/openDeepLink";
import closeImg from "../img/webinar/closeImg.svg";
import { encryptData } from "./Common/EncryptDecrypt";

const CustomNavbar = (props) => {
  const [hoverProducts, setHoverProducts] = useState(false);
  const [hoverSolutions, setHoverSolutions] = useState(false);
  const [hoverResources, setHoverResources] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [isLoading, setIsloading] = useState(true);
  const [isHoveringPlatform, setIsHoveringPlatform] = useState(false);
  const gaEventTracker = useAnalyticsEventTracker("Menu Bar");
  const [isWebBanner, setIsWebBanner] = useState(true);

  const handleMouseHoverProduct = () => {
    setHoverProducts(true);
    setHoverSolutions(false);
    setHoverResources(false);
  };

  const handleMouseHoverSolutions = () => {
    setHoverProducts(false);
    setHoverSolutions(true);
    setHoverResources(false);
  };
  const handleMouseHoverResources = () => {
    setHoverProducts(false);
    setHoverSolutions(false);
    setHoverResources(true);
  };
  const handleMouseLeave = () => {
    setHoverProducts(false);
    setHoverSolutions(false);
    setHoverResources(false);
  };
  var {
    mClass,
    nClass,
    cClass,
    slogo,
    hbtnClass,
    blueArrow,
    headerClass,
    productLogo,
  } = props;
  let userLogin = localStorage.hasOwnProperty("login-user");
  let userName = localStorage.hasOwnProperty("login-user")
    ? JSON.parse(localStorage["login-user"]).name
    : "";
  let currentLicenseId = localStorage.hasOwnProperty("login-user")
    ? JSON.parse(localStorage["login-user"]).currentLicenseId
    : "";
  let accessToken = localStorage.hasOwnProperty("login-user")
    ? JSON.parse(localStorage["login-user"]).access_token
    : "";
  const history = useHistory();
  let userEmail = localStorage.hasOwnProperty("login-user")
    ? JSON.parse(localStorage["login-user"]).userName
    : "";
  const details = useMemo(() => {
    return localStorage.hasOwnProperty("login-user")
      ? JSON.parse(localStorage["login-user"])
      : null;
  }, []);
  const DOMAIN_URL = process.env.REACT_APP_MANAGEMENT_DOMAIN_NAME;

  let [appPath, setApppath] = useState("");

  useEffect(() => {
    const encryptDetailsAndSetPath = async () => {
      if (userLogin && details) {
        try {
          // Encrypt the details
          const encryptedDetails = await encryptData(details);
          // Set the appPath based on license status
          if (verifyLicenseStatus(JSON.parse(localStorage["login-user"]))) {
            setApppath(
              `${DOMAIN_URL}/projectmenu/project?from="website"&data=${encryptedDetails}`
            );
          } else {
            setApppath(
              `${DOMAIN_URL}/licenses/fireflink-license?from="website"&data=${encryptedDetails}`
            );
          }
        } catch (error) {
          console.log("Error encrypting details:", error);
        }
      }
    };
    encryptDetailsAndSetPath();
  }, [userLogin, DOMAIN_URL, details]);

  const [textColor, setTextColor] = useState(
    headerClass === "position" ? "#434DB8" : "#FFFFFF"
  );
  // const [textColor, setTextColor] = useState("#DB7221");
  // const handleScroll = () => {
  //   const scrollPosition = window.scrollY;

  //   if (scrollPosition > 1) {
  //     setTextColor("#DB7221");
  //   } else {
  //     setTextColor("#DB7221");
  //   }
  // };
  const handleScroll = () => {
    const scrollPosition = window.scrollY;

    if (scrollPosition > 1 || headerClass === "position") {
      setTextColor("#434DB8");
    } else {
      setTextColor("#DB7221");
    }
  };
  function SignOut() {
    let payload = {
      userEmail,
      currentLicenseId,
      // accessToken,
    };
    signOutReq(payload)
      .then((response) => {
        if (response.data.status === "Success") {
          localStorage.removeItem("login-user");
          history.push(`/`);
          window.location.reload();
        }
      })
      .catch(() => {
        console.log(Error);
      });
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isHoveringFinder, setIsHoveringFinder] = useState(false);
  const [isHoveringCrowd, setIsHoveringCrowd] = useState(false);
  const [isHoveringCloud, setIsHoveringCloud] = useState(false);
  const [isHoveringAssets, setIsHoveringAssets] = useState(false);
  // state = {
  //     activeTab : 1
  // }

  const handleMouseOverPlatform = () => {
    setIsHoveringPlatform(true);
    setIsHoveringFinder(false);
    setIsHoveringCrowd(false);
    setIsHoveringCloud(false);
    setIsHoveringAssets(false);
    setIsloading(false);
  };
  const handleMouseOverFinder = () => {
    setIsHoveringFinder(true);
    setIsHoveringPlatform(false);
    setIsHoveringCrowd(false);
    setIsHoveringCloud(false);
    setIsHoveringAssets(false);
    setIsloading(false);
  };

  const handleMouseOverCrowd = () => {
    setIsHoveringCrowd(true);
    setIsHoveringFinder(false);
    setIsHoveringPlatform(false);
    setIsHoveringCloud(false);
    setIsHoveringAssets(false);
    setIsloading(false);
  };
  const handleMouseOverCloud = () => {
    setIsHoveringCloud(true);
    setIsHoveringCrowd(false);
    setIsHoveringFinder(false);
    setIsHoveringPlatform(false);
    setIsHoveringAssets(false);
    setIsloading(false);
  };
  const handleMouseOverAssets = () => {
    setIsHoveringAssets(true);
    setIsHoveringCloud(false);
    setIsHoveringCrowd(false);
    setIsHoveringFinder(false);
    setIsHoveringPlatform(false);
    setIsloading(false);
    setIsloading(false);
  };

  const [menu, setMenu] = React.useState(true);
  const [menu1, setMenu1] = useState(true);
  const [menu2, setMenu2] = useState(true);
  // const [isWebBanner, setIsWebBanner] = useState(true);

  const toggleNavbar = () => {
    setMenu(!menu);
    setMenu1(true);
    setMenu2(true);
  };
  const toggleNavbar1 = () => {
    setMenu1(!menu1);
    setMenu(true);
    setMenu2(true);
  };
  const toggleNavbar2 = () => {
    setMenu2(!menu2);
    setMenu1(true);
    setMenu(true);
  };

  const classOne = menu
    ? "collapse navbar-collapse mean-menu"
    : "collapse navbar-collapse show";
  const classTwo = menu
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  const classOne1 = menu1
    ? "collapse navbar-collapse mean-menu"
    : "collapse navbar-collapse show";
  const classTwo1 = menu1
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  const classOne2 = menu2 ? "collapse mean-menu" : "collapse show";
  const classTwo2 = menu2
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";

  const handleShowWebBanner = () => {
    setIsWebBanner(!isWebBanner);
    sessionStorage.setItem("isWebBanner", true);
  };

  useEffect(() => {
    if (sessionStorage.getItem("isWebBanner")) {
      setIsWebBanner(false);
    }
  }, [isWebBanner]);

  return (
    <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
      <header className={headerClass ? headerClass : "header_area"}>
        {/* {isWebBanner? (
          <div className="row webinarBannerNav ">
            <div className="col-lg-11 textCenter">
              <p className="f_size_17 f_600 white_color mb_0">
                Join Our Free Webinar on "The Evolution of Selenium – What's
                Next?" <Link to="/webinars" onClick={handleShowWebBanner} >Reserve Your Spot Now!</Link>
              </p>
            </div>
            <div className="col-lg-1">
              <button className="closeBtn" onClick={handleShowWebBanner}>
                <img src={closeImg} alt="close Icon" />
              </button>
            </div>
          </div>
        ) : (
          ""
        )} */}
        <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
          <div className={`container ${cClass}`}>
            <Link
              className={`navbar-brand  ${slogo}`}
              to={
                productLogo === "fireFlinkAcadamy" ? "/fireflink-academy" : "/"
              }
            >
              {productLogo === "fireFlinkAcadamy" ? (
                <>
                  <img
                    className="fireflink"
                    src={acadamyLogo}
                    alt="acadamyLogo"
                  />
                  <img
                    className="fireflink"
                    src={acadamyLogo}
                    alt="acadamyLogo"
                  />
                </>
              ) : (
                <>
                  <img
                    className="fireflink"
                    src={fireflinkwhite}
                    alt="Fireflink"
                  />
                  <img className="fireflink" src={fireflinklogo} alt="logo" />
                </>
              )}
            </Link>
            <div className={userLogin ? "d-flex res_menu" : "w_auto_res"}>
              {userLogin ? (
                <>
                  <button
                    onClick={toggleNavbar2}
                    className={classTwo2}
                    type="button"
                    // data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    // aria-controls="navbarSupportedContent"
                    // aria-expanded="false"
                    // aria-label="Toggle navigation"
                  >
                    <div className="profile_icon">
                      <Avatar className="profile_icon_sign">
                        {userName.split("")[0].toUpperCase()}
                      </Avatar>
                    </div>
                  </button>
                  <div className={classOne2}>
                    <div className="profile_popup_res uptri5">
                      <div className="d-flex pt-3 pl-3">
                        <div className="">
                          <Avatar className="profile_icon_sign">
                            {userName.split("")[0].toUpperCase()}
                          </Avatar>
                        </div>
                        <div className="ml-2">
                          <p className="profile_name">{userName}</p>
                          <a className="profile_email" href="">
                            {userEmail}
                          </a>
                        </div>
                      </div>
                      <div className="profile_line mt-2"></div>
                      <p
                        className="sign_out ml-20 mt-2"
                        onClick={() => {
                          SignOut();
                        }}
                      >
                        Sign Out
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <button
                onClick={toggleNavbar1}
                className={classTwo1}
                type="button"
                // data-toggle="collapse"
                data-target="#navbarSupportedContent"
                // aria-controls="navbarSupportedContent"
                // aria-expanded="false"
                // aria-label="Toggle navigation"
              >
                <div className="my-prod">
                  <img
                    src={require("../img/home/dot-grid.svg").default}
                    alt="dot grid"
                  />
                </div>
              </button>

              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                // data-toggle="collapse"
                data-target="#navbarSupportedContent"
                // aria-controls="navbarSupportedContent"
                // aria-expanded="false"
                // aria-label="Toggle navigation"
              >
                <span
                  className={
                    userLogin ? "menu_toggle_res menu_toggle" : "menu_toggle"
                  }
                >
                  <span
                    className={headerClass ? "hamburger whiteNav" : "hamburger"}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                  <span
                    className={
                      headerClass
                        ? "hamburger-cross whiteNav"
                        : "hamburger-cross"
                    }
                    onClick={() => {
                      setMenu(true);
                    }}
                  >
                    <span></span>
                    <span></span>
                  </span>
                </span>
              </button>
            </div>
            <div className={`  ${classOne1}`} id="navbarSupportedContent">
              <div className="my_prod_res">
                <ul
                  class="nav nav-tabs navbar-light uptri6 dropdown-menu"
                  id="myTab"
                  role="tablist"
                >
                  {userLogin ? (
                    <>
                      <li class="nav-item submenuhead">
                        <a
                          className={
                            isLoading || isHoveringPlatform ? "prodcolor" : ""
                          }
                          onMouseOver={handleMouseOverPlatform}
                          // onMouseOut={handleMouseOutPlatform}

                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                          href={appPath}
                          target="_blank"
                        >
                          <img
                            className="mt_15"
                            src={
                              require("../img/home/fireflink-platform.svg")
                                .default
                            }
                            alt="FireFlink Platform"
                          />
                        </a>
                        <h3 className="my_prod_txt">
                          A high-speed test automation platform
                        </h3>
                        <div className="dashed_border mt_15"></div>
                      </li>
                    </>
                  ) : (
                    <>
                      <li class="nav-item submenuhead">
                        <a
                          className={
                            isLoading || isHoveringPlatform ? "prodcolor" : ""
                          }
                          onMouseOver={handleMouseOverPlatform}
                          // onMouseOut={handleMouseOutPlatform}

                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                          href="/signin?product=fireflink-platform"
                          target="_blank"
                        >
                          <img
                            className="mt_15"
                            src={
                              require("../img/home/fireflink-platform.svg")
                                .default
                            }
                            alt="FireFlink Platform"
                          />
                        </a>
                        <h3 className="my_prod_txt">
                          A high-speed test automation platform
                        </h3>
                        <div className="dashed_border mt_15"></div>
                      </li>
                    </>
                  )}

                  <li class="nav-item submenuhead">
                    <div>
                      <a
                        className={
                          isHoveringFinder ? "prodcolor cursor_pointer" : ""
                        }
                        // onMouseOver={handleMouseOverFinder}
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                        // href="/fireflink-finder"
                        // onClick={() => {
                        //   isAppInstalled(deepLink)
                        //     .then((isInstalled) => {
                        //       if (isInstalled) {
                        //         console.log("App is installed.");
                        //         // Handle if the app is installed
                        //       } else {
                        //         history.push('/fireflink-finder/#scrollDownloadFinderSection')
                        //         // Handle if the app is not installed
                        //       }
                        //     })
                        //     .catch((error) => {
                        //       console.error("Error occurred:", error);
                        //     });
                        // }}
                        href="/signin?product=Fireflink-Finder"
                        // target="_blank"
                      >
                        <img
                          src={
                            require("../img/home/fireflink-finder.svg").default
                          }
                          alt="FireFlink Finder"
                        />
                      </a>
                      <h3 className="my_prod_txt">
                        All-in-one tool for locating UI elements!
                      </h3>
                      {/* <div className="dashed_border mt_15"></div> */}
                    </div>
                  </li>
                  {/* 
                  <li class="nav-item submenuhead">
                    <div>
                      <a
                        className={isHoveringAssets ? "prodcolor" : ""}
                        onMouseOver={handleMouseOverAssets}
                        role="tab"
                        aria-controls="assets"
                        aria-selected="true"
                        href="/fireflink-assets"
                      >
                        <div className="menu_beta ml-90">Beta</div>
                        <img
                          src={
                            require("../img/home/fireflink-assets.svg").default
                          }
                          alt="FireFlink Assets"
                        />
                      </a>
                      <h3 className="my_prod_txt">
                        Marketplace for all the software testing assets
                      </h3>
                      <div className="dashed_border mt_15"></div>
                    </div>
                  </li> */}
                  {/* <li class="nav-item submenuhead">
                    <div>
                      <a
                        className={isHoveringCloud ? "prodcolor" : ""}
                        onMouseOver={handleMouseOverCloud}
                        role="tab"
                        aria-controls="cloud"
                        aria-selected="true"
                        href="/fireflink-cloud"
                      >
                        <div className="menu_beta ml-93">Beta</div>
                        <img
                          src={
                            require("../img/home/fireflink-cloud.svg").default
                          }
                          alt="FireFlink Cloud"
                        />
                      </a>

                      <h3 className="my_prod_txt">
                        Web and mobile application-testing platform
                      </h3> */}
                  {/* <div className="dashed_border mt_15"></div> */}
                  {/* </div>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className={classOne} id="navbarSupportedContent">
              <div className="Mview">
                <DropDownMenu userLogin={userLogin} />
              </div>
              <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                <li
                  className="dropdown  submenu nav-item "
                  onMouseEnter={handleMouseHoverProduct}
                  onMouseLeave={handleMouseLeave}
                >
                  <Link
                    to="./"
                    title="Products"
                    className={
                      hoverProducts
                        ? "dropdown-toggle nav-link prodtexthover"
                        : "dropdown-toggle nav-link "
                    }
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Products
                    <img
                      className="arrow"
                      src={
                        hoverProducts
                          ? OrangeDownarrow
                          : window.scrollY == 0
                          ? headerClass
                            ? bluearrow
                            : arrow
                          : bluearrow
                      }
                      alt="arrow"
                    />
                  </Link>

                  <div className="sce_btn">
                    <ul
                      className={
                        userLogin
                          ? "nav nav-tabs ml-90 dropdown-menu"
                          : "nav nav-tabs ml-90 dropdown-menu"
                      }
                      id="myTab"
                      role="tablist"
                    >
                      {/* <div className="submenutab"> */}

                      <li class="inline-li nav-item submenuhead">
                        <a
                          className={
                            isLoading || isHoveringPlatform ? "prodcolor" : ""
                          }
                          onMouseOver={handleMouseOverPlatform}
                          // onMouseOut={handleMouseOutPlatform}
                          href="/fireflink-platform"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          FireFlink Platform
                        </a>
                        <div
                          className={
                            isLoading || isHoveringPlatform
                              ? "active_line"
                              : "d-none"
                          }
                        ></div>
                      </li>

                      <li class="inline-li nav-item submenuhead  ml-90">
                        {/* <Link to="/fireflink-finder"> */}
                        <a
                          className={isHoveringFinder ? "prodcolor" : ""}
                          onMouseOver={handleMouseOverFinder}
                          // onMouseOut={handleMouseOutFinder}
                          // href="/fireflink-finder"
                          href="/fireflink-finder"
                          role="tab"
                          aria-controls="home"
                          aria-selected="true"
                        >
                          {/* <Link to="/fireflink-finder"></Link> */}
                          FireFlink Finder
                        </a>
                        {/* </Link> */}
                        <div
                          className={
                            isHoveringFinder ? "active_line" : "d-none"
                          }
                        ></div>
                      </li>
                      {/* <li class="inline-li nav-item submenuhead">
                      
                          <a
                            className={isHoveringCrowd ? "prodcolor" : ""}
                            onMouseOver={handleMouseOverCrowd}
                            href="/fireflink-crowd"
                            role="tab"
                            aria-controls="crowd"
                            aria-selected="true"
                          >
                            FireFlink Crowd
                          </a>
                        <div
                          className={isHoveringCrowd ? "active_line" : "d-none"}
                        ></div>
                      </li> */}

                      <li class="inline-li nav-item submenuhead  ml-90">
                        <div className="beta">Beta</div>
                        <a
                          className={isHoveringAssets ? "prodcolor" : ""}
                          onMouseOver={handleMouseOverAssets}
                          // onMouseOut={handleMouseOutAssets}
                          href="/fireflink-assets"
                          role="tab"
                          aria-controls="assets"
                          aria-selected="true"
                        >
                          FireFlink Assets
                        </a>
                        <div
                          className={
                            isHoveringAssets ? "active_line" : "d-none"
                          }
                        ></div>
                      </li>
                      <li class="inline-li nav-item submenuhead  ml-90">
                        <div className="beta">Beta</div>
                        <Link to="/fireflink-cloud">
                          <a
                            className={isHoveringCloud ? "prodcolor" : ""}
                            onMouseOver={handleMouseOverCloud}
                            // onMouseOut={handleMouseOutCloud}
                            // href="/fireflink-cloud"
                            role="tab"
                            aria-controls="cloud"
                            aria-selected="true"
                          >
                            FireFlink Cloud
                          </a>
                        </Link>
                        <div
                          className={isHoveringCloud ? "active_line" : "d-none"}
                        ></div>
                      </li>

                      <div className="bottomline"></div>
                      <div class="tab-content" id="myTabContent">
                        {isLoading && (
                          <div
                            className="tab-pane fade show active"
                            id="platform"
                            role="tabpanel"
                            aria-labelledby="platform-tab"
                          >
                            <div className="row mt_10 mb_30">
                              <div className="col-lg-4">
                                <div className="hovebox">
                                  <div className="submenulist">
                                    <div className="">
                                      <img
                                        src={WebAutomation}
                                        alt="web automation testing"
                                      />
                                    </div>
                                    <div className="submenutext">
                                      <Link
                                        to="/fireflink-platform/web-automation-testing"
                                        exact
                                        className="item"
                                      >
                                        <h3 className="prodcolor">
                                          Web Automation Testing
                                        </h3>
                                      </Link>
                                      <p>Rapid Web Testing Platform</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img
                                      src={VisualTesting}
                                      alt="visual Automation testimg"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <NavLink
                                      to="/fireflink-platform/visual-testing"
                                      exact
                                      className="item"
                                    >
                                      <h3>Visual Testing</h3>
                                    </NavLink>
                                    <p className="black">
                                      Performance Test for Optimized UX
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img
                                      src={ManualTest}
                                      alt="Manual test case management"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <Link
                                      to="/fireflink-platform/manual-test-case-management"
                                      exact
                                      className="item"
                                    >
                                      <h3>Manual Test Case Management</h3>
                                    </Link>
                                    <p className="black">
                                      Convenient Manual Test Execution
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row ">
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img
                                      src={MobileAutomation}
                                      alt="Mobile Automation testing"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <Link
                                      to="/fireflink-platform/mobile-app-automation-testing"
                                      exact
                                      className="item"
                                    >
                                      <h3>Mobile Automation Testing</h3>
                                    </Link>
                                    <p className="black">
                                      Native & Hybrid App Testing{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img
                                      src={UiTest}
                                      alt="Ui performance Automation testing"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <NavLink
                                      to="/fireflink-platform/ui-performance-testing"
                                      exact
                                      className="item"
                                    >
                                      <h3>UI Performance Testing</h3>
                                    </NavLink>
                                    <p className="black">
                                      Finest App "Feel" with UI Testing
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img src={Defect} alt="Defect management" />
                                  </div>
                                  <div className="submenutext">
                                    <Link
                                      to="/fireflink-platform/defect-management"
                                      exact
                                      className="item"
                                    >
                                      <h3>Defect Management</h3>
                                    </Link>
                                    <p className="black">
                                      Custom Templates for Defect Management
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-30">
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img
                                      src={ApiAutomation}
                                      alt="Api automation testing"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <Link
                                      to="/fireflink-platform/api-automation-testing"
                                      exact
                                      className="item"
                                    >
                                      <h3>API Automation Testing</h3>
                                    </Link>
                                    <p className="black">
                                      For Enhanced API Test Coverage
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4"></div>
                              <div className="col-lg-4"></div>
                            </div>
                          </div>
                        )}
                        {isHoveringPlatform ? (
                          <div
                            class="tab-pane fade show active"
                            id="platform"
                            role="tabpanel"
                            aria-labelledby="platform-tab"
                          >
                            <div className="row mb_30">
                              <div className="col-lg-4">
                                <div className="hovebox">
                                  <div className="submenulist">
                                    <div className="">
                                      <img
                                        src={WebAutomation}
                                        alt="web automation testing"
                                      />
                                    </div>
                                    <div className="submenutext">
                                      <Link
                                        to="/fireflink-platform/web-automation-testing"
                                        exact
                                        className="item"
                                      >
                                        <h3 className="prodcolor">
                                          Web Automation Testing
                                        </h3>
                                      </Link>
                                      <p>Rapid Web Testing Platform</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img
                                      src={VisualTesting}
                                      alt="visual Automation testing"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <NavLink
                                      to="/fireflink-platform/visual-testing"
                                      exact
                                      className="item"
                                    >
                                      <h3>Visual Testing</h3>
                                    </NavLink>
                                    <p className="black">
                                      Performance Test for Optimized UX
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img
                                      src={ManualTest}
                                      alt="Manual test case management"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <Link
                                      to="/fireflink-platform/manual-test-case-management"
                                      exact
                                      className="item"
                                    >
                                      <h3>Manual Test Case Management</h3>
                                    </Link>
                                    <p className="black">
                                      Convenient Manual Test Execution
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row ">
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img
                                      src={MobileAutomation}
                                      alt="Mobile automation testing"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <Link
                                      to="/fireflink-platform/mobile-app-automation-testing"
                                      exact
                                      className="item"
                                    >
                                      <h3>Mobile Automation Testing</h3>
                                    </Link>
                                    <p className="black">
                                      Native & Hybrid App Testing{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img
                                      src={UiTest}
                                      alt="Ui performance Automation testing"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <NavLink
                                      to="/fireflink-platform/ui-performance-testing"
                                      exact
                                      className="item"
                                    >
                                      <h3>UI Performance Testing</h3>
                                    </NavLink>
                                    <p className="black">
                                      Finest App "Feel" with UI Testing
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img src={Defect} alt="Defect management" />
                                  </div>
                                  <div className="submenutext">
                                    <Link
                                      to="/fireflink-platform/defect-management"
                                      exact
                                      className="item"
                                    >
                                      <h3>Defect Management</h3>
                                    </Link>
                                    <p className="black">
                                      Custom Templates for Defect Management
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-40">
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img
                                      src={ApiAutomation}
                                      alt="api automation testing"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <Link
                                      to="/fireflink-platform/api-automation-testing"
                                      exact
                                      className="item"
                                    >
                                      <h3>API Automation Testing</h3>
                                    </Link>
                                    <p className="black">
                                      For Enhanced API Test Coverage
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4"></div>
                              <div className="col-lg-4"></div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {isHoveringFinder && (
                          <div class="tab-content" id="myTabContent">
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img
                                      src={WebLocatorFinder}
                                      alt="Web locator finder"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <Link
                                      to="/fireflink-finder/web-locator-finder"
                                      exact
                                      className="item"
                                    >
                                      <h3>Web Locator Finder</h3>
                                    </Link>
                                    <p className="black">
                                      Hassle-free Tool for Optimized Locators
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img
                                      src={MsLocator}
                                      alt="Ms dynamics locator finder"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <Link
                                      to="/fireflink-finder/msdynamics-locator-finder"
                                      exact
                                      className="item"
                                    >
                                      <h3>MS Dynamics Locator Finder</h3>
                                    </Link>
                                    <p className="black">
                                      MS Dynamic Apps UI Element Locator
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img
                                      src={SalesForceLocator}
                                      alt="Salesforce locator finder"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <Link
                                      to="/fireflink-finder/salesforce-locator-finder"
                                      exact
                                      className="item"
                                    >
                                      <h3>Salesforce Locator Finder</h3>
                                    </Link>
                                    <p className="black">
                                      Salesforce-Exclusive Element Finder
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-lg-4 mt-20">
                                <div className="submenulist">
                                  <div className="">
                                    <img
                                      src={MobileLocator}
                                      alt="Mobile locator finder"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <Link
                                      to="/fireflink-finder/mobile-app-locator-finder"
                                      exact
                                      className="item"
                                    >
                                      <h3>Mobile Locator Finder</h3>
                                    </Link>
                                    <p className="black">
                                      Synchronous Android/iOS Element Finder
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-4"></div>
                            </div>
                          </div>
                        )}
                        {isHoveringCrowd && (
                          <div class="tab-content" id="myTabContent">
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img
                                      src={
                                        require("../img/home/web-crowd.svg")
                                          .default
                                      }
                                      alt="web crowd automation testing"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <Link to="/fireflink-crowd/#web_crowd">
                                      <h3>Web Crowd Testing</h3>
                                    </Link>
                                    <p className="black">
                                      Comprehensive Web Crowd-Sourced Testing
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img
                                      src={
                                        require("../img/home/mobile-crowd.svg")
                                          .default
                                      }
                                      alt="Mobile crowd automation testing"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <Link to="/fireflink-crowd/#mobile_crowd">
                                      <h3>Mobile Crowd Testing</h3>
                                    </Link>
                                    <p className="black">
                                      Massive Crowd-Sourced Mobile Testing
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img
                                      src={
                                        require("../img/home/web-mobile-crowd.svg")
                                          .default
                                      }
                                      alt="Web and mobile crowd automation testing"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <Link to="/fireflink-crowd/#webnmob_crowd">
                                      <h3>Web and Mobile Crowd Testing</h3>
                                    </Link>
                                    <p className="black">
                                      Crowd Sourced Web & Mobile App Testing
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {isHoveringCloud && (
                          <div class="tab-content" id="myTabContent">
                            <div className="row mb_30">
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img src={CloudMobile} alt="cloud mobile" />
                                  </div>
                                  <div className="submenutext">
                                    <Link to="/fireflink-cloud/#cloud-mobile">
                                      <h3>Cloud Mobile</h3>
                                    </Link>
                                    {/* <Link
                                    to="/fireflink-cloud"
                                    exact
                                    className="item"
                                  >
                                    <h3 className="prodcolor">Cloud Mobile</h3>
                                  </Link> */}
                                    <p className="black">
                                      Test Concurrently across 1000s of Devices
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img
                                      src={CloudWeb}
                                      alt="Cloud web automation"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <Link to="/fireflink-cloud/#cloud-web">
                                      <h3>Cloud Web</h3>
                                    </Link>
                                    <p className="black">
                                      Universal Compatibility with Browsers and
                                      OSes
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img
                                      src={CloudLive}
                                      alt="Cloud live automation testing"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <Link to="/fireflink-cloud/#cloud-live">
                                      <h3>Cloud Live Testing</h3>
                                    </Link>
                                    <p className="black">
                                      Live Tests with Minimal Efforts across
                                      Environments
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {isHoveringAssets && (
                          <div class="tab-content" id="myTabContent">
                            <div className="row mb_30">
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img
                                      src={PlatformAssets}
                                      alt="Platform assets"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <Link
                                      to="/fireflink-assets/#platform_assets"
                                      exact
                                      className="item"
                                    >
                                      <h3 className="prodcolor">
                                        Platform Assets
                                      </h3>
                                    </Link>
                                    <p className="black">
                                      Accelerate Business Platform Application
                                      Testing
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img
                                      src={DomainAssets}
                                      alt="Domain assets"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <Link
                                      to="/fireflink-assets/#domain_assets"
                                      exact
                                      className="item"
                                    >
                                      <h3>Domain Assets</h3>
                                    </Link>
                                    <p className="black">
                                      Off-The-Shelf Test Cases for any Domain
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4">
                                <div className="submenulist">
                                  <div className="">
                                    <img
                                      src={TemplateStore}
                                      alt="Templates store"
                                    />
                                  </div>
                                  <div className="submenutext">
                                    <Link
                                      to="/fireflink-assets/#template_store"
                                      exact
                                      className="item"
                                    >
                                      <h3>Templates Store</h3>
                                    </Link>
                                    <p className="black">
                                      Buy and Sell Templates With Asset Store
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </ul>
                  </div>
                </li>
                <li
                  className="dropdown submenu  nav-item"
                  onMouseEnter={handleMouseHoverSolutions}
                  onMouseLeave={handleMouseLeave}
                >
                  <Link
                    to="./"
                    title="Solutions"
                    className={
                      hoverSolutions
                        ? "dropdown-toggle nav-link prodtexthover"
                        : "dropdown-toggle nav-link"
                    }
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Solutions
                    <img
                      className="arrow"
                      src={
                        hoverSolutions
                          ? OrangeDownarrow
                          : window.scrollY == 0
                          ? headerClass
                            ? bluearrow
                            : arrow
                          : bluearrow
                      }
                      alt="arrow"
                    />
                  </Link>

                  <ul
                    role="menu"
                    className={
                      userLogin
                        ? "ml-100 dropdown-menu"
                        : "ml-100 dropdown-menu"
                    }
                  >
                    <div className="row mt_30 mb_30">
                      <div className="col-lg-4">
                        <div className="hovebox">
                          <div className="submenulist">
                            <div className="">
                              <img src={SalesForce} alt="salesforce" />
                            </div>
                            <div className="submenutext">
                              <NavLink
                                to="/solution/salesforce-automation-testing"
                                exact
                                className="item"
                              >
                                <h3 className="prodcolor">Salesforce</h3>
                              </NavLink>
                              <p>End to End Salesforce testing ensured</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div className="">
                            <img src={MsDynamics} alt="MS Dynamics" />
                          </div>
                          <div className="submenutext">
                            <NavLink
                              to="/solution/msdynamics-automation-testing"
                              exact
                              className="item"
                            >
                              <h3>MS Dynamics</h3>
                            </NavLink>
                            <p className="black">
                              Complex system testing handled consistently
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div className="">
                            <img src={SapAutomation} alt="sap" />
                          </div>
                          <div className="submenutext">
                            <NavLink
                              to="/solution/sap-automation-testing"
                              exact
                              className="item"
                            >
                              <h3>SAP</h3>
                            </NavLink>
                            <p className="black">
                              Off the shelf assets for SAP
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb_30">
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div className="">
                            <img src={Oracle} alt="Oracle" />
                          </div>
                          <div className="submenutext">
                            <NavLink
                              to="/solution/oracle-automation-testing"
                              exact
                              className="item"
                            >
                              <h3>Oracle </h3>
                            </NavLink>
                            <p className="black">
                              A unified solution for quality test outcome{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div className="">
                            <img src={ServiceNow} alt="Service Now" />
                          </div>
                          <div className="submenutext">
                            <NavLink
                              to="/solution/service-now-automation-testing"
                              exact
                              className="item"
                            >
                              <h3>Service Now</h3>
                            </NavLink>
                            <p className="black">
                              Rapid agile adaptability for your ServiceNow
                              applications
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div className="">
                            <img src={WorkDayAutomation} alt="workday" />
                          </div>
                          <div className="submenutext">
                            <NavLink
                              to="/solution/workday-automation-testing"
                              exact
                              className="item"
                            >
                              <h3>Workday</h3>
                            </NavLink>
                            <p className="black">
                              Commit to the depth of test coverage of your
                              workday based app’s
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div className="">
                            <img src={PeopleSoft} alt="PeopleSoft" />
                          </div>
                          <div className="submenutext">
                            <NavLink
                              to="/solution/peoplesoft-automation-testing"
                              exact
                              className="item"
                            >
                              <h3>PeopleSoft </h3>
                            </NavLink>
                            <p className="black">
                              Exclusive assets library for PeopleSoft platform
                              testing
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ul>
                </li>

                <li className="nav-item">
                  <NavLink
                    title="Pricing"
                    className="nav-link"
                    to="/fireflink-platform-pricing"
                  >
                    Pricing
                  </NavLink>
                </li>

                <li
                  className="dropdown submenu  nav-item"
                  onMouseEnter={handleMouseHoverResources}
                  onMouseLeave={handleMouseLeave}
                >
                  <Link
                    to="./"
                    title="Resources"
                    className={
                      hoverResources
                        ? "dropdown-toggle nav-link prodtexthover"
                        : "dropdown-toggle nav-link"
                    }
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Resources
                    <img
                      className="arrow"
                      src={
                        hoverResources
                          ? OrangeDownarrow
                          : window.scrollY == 0
                          ? headerClass
                            ? bluearrow
                            : arrow
                          : bluearrow
                      }
                      alt="arrow"
                    />
                  </Link>

                  <ul
                    role="menu"
                    className={
                      userLogin ? "mr-90 dropdown-menu" : "mr-90 dropdown-menu"
                    }
                  >
                    <div className="row mt_30 mb_30">
                      <div className="col-lg-4">
                        <div className="hovebox">
                          <div className="submenulist">
                            <div className="">
                              <img src={Academy} alt="academy" />
                            </div>
                            <div className="submenutext">
                              <NavLink
                                to="/fireflink-academy"
                                exact
                                className="item"
                              >
                                <h3>Academy</h3>
                              </NavLink>
                              <p className="black">
                                Your Gateway to Master Proficiency in FireFlink
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="hovebox">
                          <div className="submenulist">
                            <div className="">
                              <img src={VideoTutorials} alt="video tutorials" />
                            </div>
                            <div className="submenutext">
                              <NavLink
                                to="/video-tutorial"
                                exact
                                className="item"
                              >
                                <h3>Video Tutorials</h3>
                              </NavLink>
                              <p className="black">
                                Simplified and Engaging Video Courses{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div className="">
                            <img src={Documentation} alt="Documentation" />
                          </div>
                          <div className="submenutext">
                            <NavLink to="/documentation" exact className="item">
                              <h3>Documentation</h3>
                            </NavLink>
                            <p className="black">
                              The Complete FireFlink Platform User Guide
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div className="">
                            <img src={CaseStudy} alt="Case studies" />
                          </div>
                          <div className="submenutext">
                            <NavLink to="/case-studies" exact className="item">
                              <h3>Case Studies</h3>
                            </NavLink>
                            <p className="black">
                              Insightful Software Testing Case Studies
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div className="">
                            <img src={ReleaseNotes} alt="Release notes" />
                          </div>
                          <div className="submenutext">
                            <NavLink
                              to="/release-notes/latest-version"
                              exact
                              className="item"
                            >
                              <h3>Release Notes</h3>
                            </NavLink>
                            <p className="black">
                              Latest Enhancements and Feature Updates
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div className="">
                            <img src={Blogs} alt="blogs" />
                          </div>
                          <div className="submenutext">
                            <NavLink to="/blogs" exact className="item">
                              <h3 className="prodcolor">Blogs</h3>
                            </NavLink>
                            <p>Fascinating Blogs on Software Testing</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-30">
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div className="">
                            <img src={Support} alt="Support" />
                          </div>
                          <div className="submenutext">
                            <h3>
                              {" "}
                              <a
                                target="_blank"
                                href="http://support.fireflink.com"
                              >
                                {" "}
                                Support{" "}
                              </a>{" "}
                            </h3>
                            {/* </Link> */}
                            <p className="black">
                              Have a concern? Reach out to us now
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="submenulist">
                          <div className="">
                            <img src={PreRequites} alt="PreRequites" />
                          </div>
                          <div className="submenutext">
                            <NavLink
                              to="/prerequisites/cloud-version"
                              exact
                              className="item"
                            >
                              <h3>Prerequisites</h3>
                            </NavLink>
                            <p className="black">
                              System requirements for FireFlink Platform Client
                              and Server setup
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ul>
                </li>
              </ul>

              {userLogin ? (
                <>
                  <ul
                    className={`navbar-nav menu ml-4 mr-3 ${nClass}`}
                    id="my-prod-menu"
                  >
                    <li className="dropdown  submenu nav-item ">
                      <div className="my-prod-signin">
                        <h3 className="my-prod-menu-txt">My Products</h3>
                        <div className="">
                          <img
                            src={require("../img/home/dot-grid.svg").default}
                            alt="dot grid"
                          />
                        </div>
                      </div>
                      <ul
                        class="nav nav-tabs navbar-light dropdown-menu uptri3"
                        id="myTab"
                        role="tablist"
                      >
                        <li class="nav-item submenuhead">
                          <a
                            className={
                              isLoading || isHoveringPlatform ? "prodcolor" : ""
                            }
                            onMouseOver={handleMouseOverPlatform}
                            // onMouseOut={handleMouseOutPlatform}
                            href={appPath}
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                            target="_blank"
                          >
                            <img
                              className="mt_15"
                              src={
                                require("../img/home/fireflink-platform.svg")
                                  .default
                              }
                              alt="FireFlink Platform"
                            />
                          </a>
                          <h3 className="my_prod_txt">
                            A high-speed test automation platform
                          </h3>
                          <div className="dashed_border mt_15"></div>
                        </li>

                        <li class="nav-item submenuhead">
                          <div>
                            <div
                              className="cursorPointer"
                              onClick={() => {
                                openDeepLink(
                                  "openfireflinkclient://",
                                  "/fireflink-finder/#download",
                                  history
                                );
                              }}
                            >
                              <img
                                src={
                                  require("../img/home/fireflink-finder.svg")
                                    .default
                                }
                                alt="FireFlink Finder"
                              />
                            </div>
                            <h3 className="my_prod_txt">
                              All-in-one tool for locating UI elements!
                            </h3>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul
                    className={`navbar-nav menu  ${nClass}`}
                    id="my-profile-menu"
                  >
                    <li className="dropdown  submenu nav-item ">
                      <Avatar className="profile_icon">
                        {userName?.split("")[0].toUpperCase()}
                      </Avatar>
                      <ul
                        class="nav nav-tabs navbar-light dropdown-menu uptri4"
                        id="myTab"
                        role="tablist"
                      >
                        <div className="d-flex pt-3 pl-3">
                          <div className="">
                            <Avatar className="profile_icon_sign">
                              {userName.split("")[0].toUpperCase()}
                            </Avatar>
                          </div>
                          <div className="ml-2">
                            <p className="profile_name">{userName}</p>
                            <a className="profile_email" href="">
                              {userEmail}
                            </a>
                          </div>
                        </div>
                        <div className="profile_line mt-3"></div>
                        <p
                          className="sign_out ml-20 mt-2"
                          onClick={() => {
                            SignOut();
                          }}
                        >
                          Sign Out
                        </p>
                      </ul>
                    </li>
                  </ul>
                  {/* <div>
                    <Avatar className="profile_icon">{userName.split("")[0].toUpperCase()}</Avatar>
                  </div> */}
                </>
              ) : (
                <>
                  <ul className={`navbar-nav menu ml-4 ${nClass}`}>
                    <GetDemoNavButton />
                    {/* <a
                      className={`btn_get btn_hover ${hbtnClass}`}
                      onClick={() => gaEventTracker("Start a Free Trail")}
                      href="/signup"
                    >
                      Get a Demo
                    </a> */}
                  </ul>
                  <ul
                    className={`navbar-nav menu ml-4 ${nClass}`}
                    id="my-prod-menu"
                  >
                    <li className="dropdown  submenu nav-item ">
                      <div className="my-prod">
                        <img
                          src={require("../img/home/dot-grid.svg").default}
                          alt="dot grid"
                        />
                      </div>
                      <ul
                        class="nav nav-tabs navbar-light dropdown-menu uptri3"
                        id="myTab"
                        role="tablist"
                      >
                        <li class="nav-item submenuhead">
                          <a
                            className={
                              isLoading || isHoveringPlatform ? "prodcolor" : ""
                            }
                            onMouseOver={handleMouseOverPlatform}
                            // onMouseOut={handleMouseOutPlatform}

                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                            href="/signin?product=fireflink-platform"
                            target="_blank"
                          >
                            <img
                              className="mt_15"
                              src={
                                require("../img/home/fireflink-platform.svg")
                                  .default
                              }
                              alt="FireFlink Platform"
                            />
                          </a>
                          <h3 className="my_prod_txt">
                            A high-speed test automation platform
                          </h3>
                          <div className="dashed_border mt_15"></div>
                        </li>

                        <li class="nav-item submenuhead">
                          <div>
                            <a
                              className={
                                isHoveringFinder
                                  ? "prodcolor cursor_pointer"
                                  : ""
                              }
                              // onMouseOver={handleMouseOverFinder}
                              role="tab"
                              aria-controls="home"
                              aria-selected="true"
                              // onClick={()=>
                              //   {
                              //     isAppInstalled(deepLink)
                              //       .then((isInstalled) => {
                              //         if (isInstalled) {
                              //           console.log("App is installed.");
                              //           // Handle if the app is installed
                              //         } else {
                              //           history.push('/fireflink-finder/#scrollDownloadFinderSection')
                              //           console.log("App is not installed.");
                              //           // Handle if the app is not installed
                              //         }
                              //       })
                              //       .catch((error) => {
                              //         console.error("Error occurred:", error);
                              //       });
                              //   }}
                              // href="/fireflink-finder"
                              href="/signin?product=Fireflink-Finder"
                              // target="_blank"
                            >
                              <img
                                src={
                                  require("../img/home/fireflink-finder.svg")
                                    .default
                                }
                                alt="FireFlink Finder"
                              />
                            </a>
                            <h3 className="my_prod_txt">
                              All-in-one tool for locating UI elements!
                            </h3>
                            {/* <div className="dashed_border mt_15"></div> */}
                          </div>
                        </li>

                        {/* <li class="nav-item submenuhead">
                          <div>
                            <a
                              className={isHoveringAssets ? "prodcolor" : ""}
                              onMouseOver={handleMouseOverAssets}
                              role="tab"
                              aria-controls="assets"
                              aria-selected="true"
                              href="/fireflink-assets"
                             
                            >
                              <div className="menu_beta ml-90">Beta</div>
                              <img
                                src={
                                  require("../img/home/fireflink-assets.svg")
                                    .default
                                }
                                alt="FireFlink Assets"
                              />
                            </a>
                            <h3 className="my_prod_txt">
                              Marketplace for all the software testing assets
                            </h3>
                            <div className="dashed_border mt_15"></div>
                          </div>
                        </li>
                        <li class="nav-item submenuhead">
                          <div>
                            <a
                              className={isHoveringCloud ? "prodcolor" : ""}
                              onMouseOver={handleMouseOverCloud}
                              role="tab"
                              aria-controls="cloud"
                              aria-selected="true"
                              href="/fireflink-cloud"
                           
                            >
                              <div className="menu_beta ml-93">Beta</div>
                              <img
                                src={
                                  require("../img/home/fireflink-cloud.svg")
                                    .default
                                }
                                alt="FireFlink Cloud"
                              />
                            </a>

                            <h3 className="my_prod_txt">
                              Web and mobile application-testing platform
                            </h3>
                          </div>
                        </li> */}
                      </ul>
                    </li>
                  </ul>
                  <ul className={`navbar-nav menu ml-4 ${nClass}`}>
                    <SignInButton
                      path="/signin"
                      isHeader={headerClass ? headerClass : false}
                    />
                  </ul>
                </>
              )}
            </div>
          </div>
        </nav>
      </header>
    </Sticky>
  );
};

export default CustomNavbar;
