// src/components/MessageList.js
import React from 'react';
import Message from './Message';

const MessageList = ({ messages }) => {
  return (
    <div className="message-list">
      {messages.map((message, index) => (
        <Message
          key={index}
          message={message}
          isUser={message.role === 'user'}
        />
      ))}
    </div>
  );
};

export default MessageList;
