import React, { useState, useEffect } from "react";
import { Button, Modal, Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";

function ModalComp({
  show,
  handleScroll,
  handleClose,
  activeKey,
  className = "",
}) {
  const [activeTab, setActiveTab] = useState(activeKey);
  const handleTabSelect = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  useEffect(() => {
    setActiveTab(activeKey);
  }, [activeKey]);

  const modalContent1 = () => {
    return (
      <>
        <p>
          <span>1.</span> This document outlines the terms of use (“Terms”) for
          the services provided by FireFlink Private Limited, a testing
          automation company with its principal place of business at 4th floor,
          IndiQube South Mile Building, No. 17 and 17, 1, S End Rd, Vijayarangam
          Layout, Basavanagudi, Bengaluru, Karnataka-560004, India
          (“Company/We/Us”). By accessing or using any services provided by the
          Company, You agree to be bound by these Terms. If You do not agree
          with these Terms, please refrain from using our services.
        </p>
        <h3>
          <span>2.</span> ACCEPTANCE OF TERMS
        </h3>
        <p>
          By accessing or using our services, You acknowledge that You have
          read, understood, and agree to be bound by these Terms. These Terms as
          well as the{" "}
          <button
            onClick={() => {
              setActiveTab("tab2");
            }}
            className="prodcolor b_none ml_5 mr_5"
          >
            Privacy Policy
          </button>{" "}
          and all other applicable laws and regulations govern Your access and
          use of the software and services provided by the Company
        </p>
        <p>
          You have been authorized to use and access this Platform by a Licensee
          of the Platform, as a Representative of such Licensee, and are
          required to accept these Terms to enable you to use and access the
          Platform. The terminologies “You”, “Your”, “User” And “Users” shall be
          read in context, and shall refer to You.
        </p>
        <h3>
          <span>3.</span> SERVICES
        </h3>
        <p>
          The Company is in the business of automating software testing by way
          of a platform and services (“Platform” and “Services”). The specific
          terms and conditions for the Platform and each service may be outlined
          in separate agreements, and such agreements will supplement these
          Terms.
        </p>
        <h3>
          <span>4.</span> USER ACCOUNT
        </h3>
        <p>
          You will be required to create a user account. You are responsible for
          maintaining the confidentiality of Your account information, including
          Your username and password. You agree to notify the Company
          immediately of any unauthorized use of Your account. You will abide by
          the password policy and requirements of the Platform to ensure the
          security of Your account.
        </p>
        <h3>
          <span>5.</span> ELIGIBILITY
        </h3>
        <p>
          You have been notified as an authorized user to use this Platform,
          which entitles you to Use and access the Platform for testing, in the
          manner required under your engagement with the Licensee.
        </p>
        <h3>
          <span>6.</span> FEES
        </h3>
        <p>
          Access to the Platform and/or Services is conditional upon payment of
          the fees promptly by the Licensee unless specified otherwise. Failure
          to pay such fees may result in the suspension or termination of
          services.
        </p>
        <h3>
          <span>7.</span> CONFIDENTIALITY
        </h3>
        <p>
          You agree to keep confidential any non-public information or materials
          provided by the Company that are marked as confidential while
          accessing FireFlinks Services through the Platform.
        </p>
        <h3>
          <span>8.</span> LIMITATION OF LIABILITY
        </h3>
        <p>
          <span>8.1</span> To the maximum extent permitted by applicable law, in
          no event shall FireFlink or its affiliates be liable for any direct,
          indirect, incidental, punitive, special or consequential damages, for
          loss of profits or confidential or other information, for business
          interruption, for personal injury, for loss of privacy, for failure to
          meet any duty including of good faith or of reasonable care, for
          negligence, and for any other pecuniary or other loss whatsoever
          arising out of or in any way related to the use or inability to use
          the Platform and any loss or damage arising out of or concerning acts
          of God or act of third party that is beyond the control of FireFlink
          or its affiliates.
        </p>
        <p>
          <span>8.2</span> FireFlink or its affiliates shall not be liable for
          any direct or indirect damage to any data provided by the User or any
          violation of third-party rights of whatsoever nature, arising out of
          or in any manner related to the use or inability to use the Platform.
        </p>
        <h3>
          <span>9.</span> TERM AND TERMINATION
        </h3>
        <p>
          <span>9.1</span> These Terms, and any posted revision, shall remain in
          full force and effect while You use the Platform and certain
          provisions may continue to apply even after termination. FireFlink may
          terminate this agreement by deleting Your account or profile without
          assigning any reasons whatsoever. On termination of this agreement,
          all of Your profile data and other information may be deleted and You
          are liable to pay any outstanding fees to FireFlink.
        </p>
        <p>
          <span>9.2</span> However, certain details are maintained with us for
          archival and legal purposes under the law. FireFlink reserves the
          right to change these Terms from time to time. If You do not agree to
          any such changes, You may stop accessing, availing, or using the
          Services. Continued access or use of the Platform and the Services
          following notice of any such changes will indicate Your acknowledgment
          of such changes and You will be bound by such revised Terms.
        </p>
        <h3>
          <span>10.</span> GOVERNING LAW AND DISPUTE RESOLUTION
        </h3>
        <p>
          These Terms are governed by the laws of India and disputes shall be
          resolved in the manner set out in the Agreement with the Licensee.
        </p>
        <h3>
          <span>11.</span> CHANGES TO TERMS
        </h3>
        <p>
          The Company reserves the right to modify these Terms at any time.
          Updated Terms will be posted on our website, and it is Your
          responsibility to review them regularly. Your continued use of the
          Services after any changes to the Terms indicates Your acceptance of
          the modified Terms.
        </p>
        <p className="mt-10">
          By using the Services of the Company, You agree to comply with these
          Terms. If You have any questions or concerns about these Terms, please
          contact us at{" "}
          <a className="prodcolor" href="mailto:support@fireflink.com">
            support@fireflink.com
          </a>
          .
        </p>
        <h3>
          <span>12.</span> COMMUNICATIONS
        </h3>
        <p>
          When You use the Platform or send emails or other data, information,
          or communication to FireFlink, You agree and understand that You are
          communicating with FireFlink through electronic records and You
          consent to receive communications via electronic records from
          FireFlink periodically and as and when required. FireFlink may
          communicate with You by email provided by You in Your communications
          or at the time of registration or by any other mode of communication,
          electronic or otherwise. FireFlink complies with its legal obligations
          in maintaining the security of its communications with You.
        </p>
        <h3>
          <span>13.</span> LICENSE
        </h3>
        <p>
          <span>13.1</span> Subject to the Agreement with the Licensee and these
          Terms and the timely payment of required fees, if any, FireFlink
          hereby grants You only a limited, non-transferable, non-exclusive,
          revocable license to make use of the services listed on the Platform
          in the manner as further described in any agreement or document
          supplementing these Terms.
        </p>
        <p>
          <span>13.2</span> The Platform is licensed, not sold, to You, even
          after installation on Your devices. FireFlink may assign this license
          agreement or any part of it without restrictions. You are not allowed
          to assign, transfer or sub-license Your rights under this license, to
          any third party.
        </p>

        <h3>
          <span>14.</span> PROHIBITED ACTIVITY/IES
        </h3>
        <p>
          <span>14.1</span> You shall not, nor permit or encourage any third
          party to, directly or indirectly (i) reverse engineer, decompile,
          disassemble, or otherwise attempt to discover or derive the source
          code, object code, or underlying structure, ideas, know-how or
          algorithms relevant to a platform or any software, documentation or
          data related to its products/website; (ii) modify, translate, or
          create derivative works based on any software provided by FireFlink;
          (iii) modify, remove or obstruct any proprietary notices or labels; or
          (iv) use any Software in any manner to assist or take part in the
          development, marketing or sale of a product potentially competitive
          with such software.
        </p>
        <p>
          <span>14.2</span> You shall not deliberately impersonate another
          person, whether real or fictional, otherwise misrepresent Your
          affiliation with a person or entity, or otherwise fake Your identity
          while registering Your account on the Platform.
        </p>
        <p>
          <span>14.3</span> You shall not transfer or offer to transfer access
          to the Platform and/or any FireFlink account or permit any third
          parties to use Your name and password, or any data on the Platform.
        </p>
        <p>
          <span>14.4</span> You shall not authorize any third party to use Your
          account.
        </p>
        <p>
          <span>14.5</span> You shall not commit or engage in, or encourage,
          induce, solicit or promote, any conduct that would constitute a
          criminal offense, give rise to civil liability, or otherwise violate
          any law or regulation; or use the Platform for any illegal or
          unauthorized purpose; You agree to comply with all local laws
          applicable to Your conduct and the content and information, including
          hyperlinks, that You upload, store, share or transmit using the
          Platform.
        </p>
        <p>
          <span>14.6</span> You shall not alter or remove, attempt to alter or
          remove any trademark, copyright, or other proprietary or legal notices
          contained in, or appearing on, the Platform or on any content
          appearing on the Platform. You may not frame or utilize framing
          techniques to enclose any trademark, logo, or other proprietary
          information (including images, content, text, page layout, or form) of
          FireFlink and our affiliates. You may not use any meta-tags or any
          other "hidden text" utilizing FireFlink's name or trademarks without
          the express written consent of FireFlink. Any unauthorized use
          terminates the permission or license granted by FireFlink. You may not
          use any FireFlink logo or other proprietary graphic or trademark as
          part of the link without FireFlink's advance express written
          permission.
        </p>
        <p>
          <span>14.7</span> You shall not employ scraping or similar techniques
          to aggregate, repurpose, adapt, copy, republish, make available, or
          otherwise communicate to the public, display, perform, transfer,
          share, distribute, or otherwise use or exploit the content except via
          the features provided in the Platform.
        </p>
        <p>
          <span>14.8</span> You shall not transmit any viruses, worms, defects,
          Trojan horses, cancelbots, spyware, other items of a contaminating or
          destructive nature, adware, packet or IP spoofing, forged routing or
          electronic mail address information or similar methods or technology
          harmful code, flood pings, malware, bot, time bomb, worm, or other
          harmful or malicious component, which or might overburden, impair or
          disrupt the Platform or networks forming part of, or connected to, the
          Platform, or which does or might restrict or inhibit any other User's
          use and of the Platform.
        </p>
        <p>
          <span>14.9</span> You shall not violate, circumvent, or attempt to
          violate or circumvent any data security measures employed by
          FireFlink; access or attempt to access data or materials which are not
          intended for Your use; log into, or attempt to log into, an account
          which You are not authorized to access; attempt to scan or test the
          vulnerability of FireFlink's server, system or network or attempt to
          breach FireFlink's data security or authentication procedures; attempt
          to interfere with the Platform by any means including, without
          limitation, hacking FireFlink's servers or systems, submitting a
          virus, overloading, mail-bombing or crashing.
        </p>
        <p>
          <span>14.10</span> You shall at all times ensure full compliance with
          the applicable provisions of applicable laws including but not limited
          to data protection and cyber security laws.
        </p>
        <p>
          <span>14.11</span> You agree to comply with the above conditions and
          acknowledge that FireFlink has the right, in its sole discretion, to
          terminate Your account or take action as in our sole discretion is
          necessary if You breach any of the above conditions or any of the
          other provisions of these Terms.
        </p>
        <p>
          <span>14.12</span> You understand that FireFlink has the right at all
          times to disclose any information (including the identity of the
          persons providing information or materials on the Platform) as
          necessary to satisfy any law, regulation, or valid governmental
          request. This may include, without limitation, disclosure of the
          information in connection with an investigation of alleged illegal
          activity or solicitation of illegal activity or response to a lawful
          court order. In addition, FireFlink may (and You hereby expressly
          authorize FireFlink to) disclose any information about You to law
          enforcement or other government officials, as FireFlink, in its sole
          discretion, believes necessary or appropriate in connection with the
          investigation and/or resolution of possible crimes, especially those
          that may involve personal injury.
        </p>
        <p>
          <span>14.13</span> Throughout these Terms, FireFlink's prior written
          consent means a communication coming from FireFlink's Legal
          Department, specifically in response to Your request, and specifically
          addressing the activity or conduct for which You seek authorization.
        </p>

        <h3>
          <span>15.</span> REPRESENTATIONS AND WARRANTIES
        </h3>
        <p>
          <span>15.1</span> This Platform and the Content under it are provided
          by FireFlink "as is" and on an "as available" basis and FireFlink and
          its affiliates and their officers, directors, employees, and agents
          make no warranties and hereby disclaim any express or implied
          warranties, including, but not limited to, the implied warranties
          (collectively, "warranties") of merchantability and fitness for a
          particular purpose.
        </p>
        <p>
          <span>15.2</span> You agree that Your use of the Platform shall be at
          Your sole risk. To the fullest extent permitted by law, FireFlink
          disclaims all warranties, express or implied, in connection with the
          use of the Platform.
        </p>
        <p>
          <span>15.3</span> FireFlink makes no representations or guarantees
          that the Platform will be free from loss, destruction, damage, any
          unauthorized access to or use of FireFlink's secure servers and/or any
          personal information and/or financial information stored therein,
          corruption, attack, any interruption or cessation of transmission to
          or from the Platform, any bugs, viruses, Trojan horses, or the like
          which may be transmitted to or through the Platform by any third
          party, and/or any errors or omissions in any content or for any loss
          or damage of any kind incurred as a result of the use of any of the
          Content accessible via the Platform. FireFlink does not warrant,
          endorse, guarantee, or assume responsibility for any product or
          service advertised or offered by a third party through the services or
          any hyperlinked services or featured in any banner or other
          advertising, and FireFlink will not be a party to or in any way be
          responsible for monitoring any transaction between You and third-party
          providers of products or services.
        </p>
        <p>
          <span>15.4</span> FireFlink makes no guarantees, representations, or
          warranties that use or results of the use of the Platform will be
          accurate, timely, reliable, uninterrupted, or without errors. Without
          prior notice, FireFlink may modify, suspend, or discontinue any part
          or all of the Platform or Your use of the Platform. In such event,
          FireFlink or its affiliates will not be liable to You or any third
          party.
        </p>
        <p>
          <span>15.5</span> FireFlink makes no guarantees, representations, or
          warranties that content accessible through the Platform by the User or
          the links provided by third parties will be free of viruses or similar
          contamination or destructive features. You agree that You assume all
          risk as to the quality and performance of the Platform and the
          accuracy and completeness of the content.
        </p>

        <h3>
          <span>16.</span> PRIVACY
        </h3>
        <p>
          FireFlink may collect some of Your personal information and data while
          accessing, availing, and/or using the Platform. Such information
          collected is only attributed to the functionality of the Platform and
          for no other purpose whatsoever. Please refer to the
          <button
            onClick={() => {
              setActiveTab("tab2");
            }}
            className="prodcolor b_none ml_5 mr_5"
          >
            Privacy Policy
          </button>{" "}
          for more information.{" "}
        </p>

        <h3>
          <span>17.</span> DISCLAIMERS{" "}
        </h3>
        <p>
          <span>17.1</span> THE APP, ITS FEATURES, AND CONTENT ARE PROVIDED "AS
          IS" "AS AVAILABLE", AND "WITH ALL FAULTS"
        </p>
        <p>
          <span>17.2</span> FireFlink shall have all the rights to take
          necessary action and claim damages that may occur due to Your
          involvement/participation in any way on Your own or through a group/s
          of people, intentionally or unintentionally in DoS/DDoS (Distributed
          Denial of Services).
        </p>

        <h3>
          <span>18</span> INTELLECTUAL PROPERTY RIGHTS{" "}
        </h3>
        <p>
          <span>18.1</span> All (i) copyrights (including, without limitation,
          the right to reproduce, distribute copies of, display and perform the
          copyrighted work and to prepare derivative works), copyright
          registrations and applications, trademark rights (including, without
          limitation, registrations and applications), patent rights, trade
          names, mask-work rights, trade secrets, moral rights, author’s rights,
          privacy rights, publicity rights, algorithms, rights in packaging,
          goodwill and other proprietary rights; (ii) intangible legal rights or
          interests evidenced by or embodied in any idea, design, concept,
          technique, invention, discovery, enhancement or improvement,
          regardless of patentability, but including patents, patent
          applications, trade secrets, and know-how; and (iii) all derivatives
          of any of the foregoing, subsisting in the content on the Platform, is
          the sole and exclusive property of FireFlink.
        </p>
        <p>
          <span>18.2</span> You shall not download or encourage others to
          download copyrighted works, trademarks, or other proprietary
          information without obtaining prior written consent from FireFlink. In
          the event of infringement, FireFlink shall in its sole discretion take
          necessary steps.
        </p>

        <h3>
          <span>19.</span> INDEMNITY
        </h3>
        <p>
          You hereby agree to indemnify, defend, and hold FireFlink and its
          affiliates and their officers, directors, and employees, harmless from
          and against any damages, liabilities, costs, and expenses, including
          attorney's fees and expenses, arising out of, incident to, or
          resulting directly or indirectly from the use of the Platform in a
          manner inconsistent with these Terms.
        </p>

        <h3>
          <span>20</span> LIABILITY{" "}
        </h3>
        <p>
          <span>20.1</span> The Company shall not be liable for any indirect,
          incidental, special, or consequential damages arising out of or in
          connection with the use of its services. The services are provided "as
          is" and "as available" without any warranties or guarantees.
        </p>
        <p>
          <span>20.2</span> The liability of the Company for any claim arising
          out of or in connection with the services shall be limited to the
          amount paid by You for the specific services giving rise to the claim.
        </p>

        <h3>
          <span>21.</span> SEVERABILITY
        </h3>
        <p>
          If any provision of these Terms is found to be invalid or
          unenforceable, the remaining provisions will continue to be valid and
          enforceable to the fullest extent permitted by law.
        </p>
        <h3>
          <span>22.</span> WAIVER
        </h3>
        <p>
          The failure of the Company to enforce any provision of these Terms
          shall not be considered a waiver of its right to enforce such
          provision or any other provision in the future.
        </p>
        <h3>
          <span>23.</span> FORCE MAJEURE
        </h3>
        <p>
          The Company shall not be liable for any delay or failure to perform
          its obligations under these Terms if such delay or failure is due to
          circumstances beyond its reasonable control, including but not limited
          to acts of God, war, terrorism, strikes, or natural disasters.
        </p>
        <h3>
          <span>24.</span> NOTICES
        </h3>
        <p>
          All notices, requests, or other communications required or permitted
          under these Terms shall be in writing and delivered to the addresses
          specified in the relevant agreement or to such other address as either
          party may designate by notice to the other party.
        </p>
        <h3>
          <span>25.</span> SURVIVAL
        </h3>
        <p>
          The provisions of these Terms that by their nature should survive
          termination, including but not limited to confidentiality obligations,
          payment obligations, and dispute resolution provisions, shall survive
          any termination or expiration of these Terms.
        </p>
        <h3>
          <span>26.</span> GRIEVANCE POLICY
        </h3>
        <p>
          If You have any grievances concerns or queries concerning these Terms,
          You may contact FireFlink’s grievance officer by email or mail. You
          may send Your grievances to the grievance officer at the following
          email address{" "}
          <a className="prodcolor" href="mailto:saichitra.v@fireflink.com">
            saichitra.v@fireflink.com
          </a>{" "}
          with the subject Grievance.
        </p>
        <p>
          By using the services of the Company, You agree to the terms outlined
          in this Terms of Use.{" "}
        </p>
      </>
    );
  };
  const modalContent2 = () => {
    return (
      <>
        <h3>End-User License Agreement</h3>
        <p>
          This End-User License Agreement (“EULA/Agreement”) is a legally
          binding agreement between FireFlink Private Limited (“FireFlink”), the
          owner and licensor of a software testing automation platform which may
          include associated media, printed materials, and online or electronic
          documentation (“the Platform”), and you (“You/Your”) the authorized
          end user and/or representative of the licensee of the Platform
          (“Licensee”). This EULA is a legally binding contract that includes
          terms that limit Your legal rights and FireFlink’s liability to You
          and shall govern all access to and use of the Platform. You hereby
          agree, without limitation or alteration, to all the terms and
          conditions contained herein.
        </p>
        <p>
          The Platform is licensed, not sold, to You by FireFlink for use
          strictly under the terms of the License entered into with the Licensee
          and the terms of this EULA.
        </p>
        <p>
          By installing, copying, or otherwise using the licensed product
          (“Platform”), You agree to be bound by the terms and conditions
          outlined in this EULA. However, if You do not agree to the terms and
          conditions outlined in this EULA, You may not download, install, or
          use the Platform.
        </p>

        <h3>
          <span>1.</span> License{" "}
        </h3>
        <h3>
          <span>1.1.</span> Scope of License{" "}
        </h3>
        <p>
          <span>1.1.1</span> You have been identified as a person authorized by
          a Licensee of the Platform (“Authorized User”), and are entitled to
          use and operate the Platform under the terms of the License granted by
          FireFlink to such Licensee. The terms and conditions of this EULA are
          to be read with the terms of the License Agreement with the Licensee.
        </p>
        <p>
          <span>1.1.2</span> FireFlink hereby grants to You a revocable,
          non-exclusive, non-transferable, limited license to download, install,
          and use the Platform, for the sole purpose of conducting software
          testing within the Licensee's organization strictly under the terms of
          this Agreement.
        </p>
        <p>
          <span>1.1.3</span> You may only use the Platform on a device that is
          owned or controlled by the Licensee and as permitted by this EULA’s
          terms and conditions.
        </p>
        <p>
          <span>1.1.4</span> The license that is granted to You by FireFlink is
          solely for commercial purposes strictly under the terms of this
          Agreement.
        </p>

        <h3>
          <span>2.</span> License Restrictions{" "}
        </h3>
        <h3>
          <span>2.1.</span> You agree not to, and You will not permit others to:{" "}
        </h3>
        <p>
          <span>2.1.1</span> License, sub-license, sell, rent, lease, assign,
          distribute, transmit, host, outsource, disclose or otherwise
          commercially exploit the Platform or make the Platform available to
          any third party, either directly or indirectly;
        </p>
        <p>
          <span>2.1.2</span> Remove, alter, or obscure any proprietary notice
          (including any notice of copyright or trademark) of FireFlink or its
          affiliates, partners, suppliers, or the licensors of the Platform.
        </p>

        <h3>
          <span>3.</span> Intellectual Property{" "}
        </h3>
        <p>
          <span>3.1</span> You hereby unconditionally agree that all rights,
          titles, and interests in the copyrights and other intellectual
          property rights in the Platform reside with FireFlink. The trademarks,
          logos, designs, and service marks appearing on the Platform are
          registered marks of FireFlink. Accordingly, nothing in this EULA or
          the Platform grants You any right to use any form of intellectual
          property contained in the Platform.
        </p>
        <p>
          <span>3.2</span> Therefore, all rights, titles, interests, and
          copyrights in and/or to the Platform, including but not limited to all
          images, graphics, animations, audio, video, music, text, data, code,
          algorithm, and information, are owned by FireFlink. Accordingly, the
          Platform is protected by all applicable copyright laws and
          international treaties, and the Licensee, and all Authorized Users
          including you are expected to use the Platform concerning all
          intellectual property contained therein, except as otherwise provided
          for in this EULA.
        </p>

        <h3>
          <span>4.</span> Installation and Use{" "}
        </h3>
        <p>
          In the event of the Licensee purchasing an On-Premise License, the
          Licensee may install and use the Platform on a shared computer or
          concurrently on different computers, and make multiple backup copies
          of the Platform, solely for the Licensee's business purpose.
        </p>

        <h3>
          <span>5.</span> Limitations to Use{" "}
        </h3>
        <p>
          <span>5.1</span> You will not:
        </p>
        <p>
          <span>5.1.1</span> Use the Platform for any purpose other than
          personal and non-commercial purposes;
        </p>
        <p>
          <span>5.1.2</span> Use the Platform for any illegal or unlawful
          purpose;
        </p>
        <p>
          <span>5.1.3</span> Gather factual content or any other portion of the
          Platform by any automated means, including but not limited to database
          scraping or screen scraping; or
        </p>
        <p>
          <span>5.1.4</span> Reverse engineer, decompile, or disassemble the
          Platform.
        </p>

        <h3>
          <span>6.</span> Updates and Support
        </h3>
        <p>
          <span>6.1</span> FireFlink may, at its sole discretion, provide
          updates, patches, or support services for the Platform. This Agreement
          applies to any such updates, patches, or support services unless other
          terms accompany them.
        </p>
        <p>
          <span>6.2</span> FireFlink will also provide technical support for the
          Platform in the manner agreed with the Licensee.
        </p>

        <h3>
          <span>7.</span> Third-Party Services
        </h3>
        <p>
          <span>7.1</span> The Platform may display, include, or make available
          third-party content (including data, information, applications, and
          other products & services) or provide links to third-party websites or
          services.
        </p>
        <p>
          <span>7.2</span> You acknowledge and agree that FireFlink shall not be
          responsible for any Third-party Services, including their accuracy,
          completeness, timeliness, validity, copyright compliance, legality,
          decency, quality, or any other aspect thereof. FireFlink does not
          assume and shall not have any liability or responsibility to You or
          any other person or entity for any Third-party Services.
        </p>
        <p>
          <span>7.3</span> You must comply with applicable Third parties' terms
          of agreement when using the Platform. Third-party Services and links
          thereto are provided solely as a convenience to You and You access and
          use them entirely at Your own risk and are subject to such third-party
          parties' terms and conditions.
        </p>

        <h3>
          <span>8.</span> Termination
        </h3>
        <p>
          This license is co-terminus with the License Agreement with the
          Licensee. In addition, Your rights under this EULA will terminate
          automatically without notice from FireFlink if you fail to comply with
          any term(s) of this Agreement.
        </p>
        <h3>
          <span>9.</span> Non-Transferability
        </h3>
        <p>
          The Licensee has the option to change or amend its Authorized Users
          and consequent rights/privileges under this EULA, provided such other
          Authorized User agrees to the terms of this EULA. Accordingly, this
          EULA is not assignable or transferable by the Licensee without the
          prior written consent of FireFlink; and any attempt to do so shall be
          void.
        </p>
        <h3>
          <span>10.</span> Notice
        </h3>
        <p>
          Any notice, report, approval, or consent required under this EULA
          shall be in writing and deemed to have been duly given if delivered by
          recorded delivery to the respective addresses of the parties.
        </p>
        <h3>
          <span>11.</span> Severability
        </h3>
        <p>
          No delay or failure to exercise, on the part of either party, any
          privilege, power, or rights under this EULA shall operate as a waiver
          of any of the terms and provisions of this EULA. Accordingly, no
          single or partial exercise of any right under this Agreement shall
          preclude further exercise of any other right under this EULA. If any
          of the outlined provisions of this EULA are deemed to be unenforceable
          or invalid in whole or in part by a court of competent jurisdiction,
          such provision(s) shall be limited to the minimum extent necessary for
          this EULA to remain in full force and effect and enforceable. The
          remaining provisions of this Agreement shall not be rendered
          unenforceable or invalid. They shall continue to be enforceable and
          valid in isolation of the unenforceable and invalid provisions of this
          EULA.
        </p>

        <h3>
          <span>12.</span> Indemnification
        </h3>
        <p>
          You hereby agree to indemnify and hold FireFlink harmless from and
          against all liabilities, damages, losses, or expenses, including but
          not limited to reasonable attorney or other professional fees in any
          claim, demand, action, or proceeding initiated by any third party
          against FireFlink, arising from any of Your acts, including without
          limitation, violating this EULA or any other agreement or any
          applicable law.
        </p>
        <h3>
          <span>13.</span> Entire Agreement
        </h3>
        <p>
          This Agreement constitutes the entire agreement between You and
          FireFlink regarding the Platform and supersedes all prior
          negotiations, understandings, or agreements.
        </p>

        <h3>
          <span>14.</span> Changes to this EULA
        </h3>
        <p>
          <span>14.1</span> FireFlink reserves the right, at its sole
          discretion, to modify or replace this Agreement at any time. If a
          revision is material we will provide at least 30 days' notice before
          any new terms take effect. What constitutes a material change will be
          determined at the sole discretion of FireFlink.
        </p>
        <p>
          <span>14.2</span> By continuing to access or use the Platform after
          any revisions become effective, You agree to be bound by the revised
          terms. If You do not agree to the new terms, You are no longer
          authorized to use the Platform.
        </p>

        <h3>
          <span>15.</span> Governing Law and Jurisdiction
        </h3>
        <p>
          Any legal action relating to this EULA shall be governed by the laws
          of India, with the courts of Bangalore, Karnataka having exclusive
          jurisdiction without reference to any conflict of laws rules.
        </p>
      </>
    );
  };

  const modalContent3 = () => {
    return (
      <>
        <h3>FIREFLINK PRIVACY POLICY - INTRODUCTION</h3>
        <p>
          FireFlink (“we” or “us” or “our”) respects the privacy of our users
          (“user” or “you”). This Privacy Policy explains how we collect, use,
          disclose, and safeguard your information when you visit our website
          <a className="prodcolor" href="/">
            {" "}www.fireflink.com{" "}
          </a>
          including any other media form, media channel, mobile website, or
          mobile application related or connected to that (collectively, the
          “Site”). Please read this privacy policy carefully. If you do not
          agree with the terms of this privacy policy, please do not access the
          Site. We reserve the right to make changes to this Privacy Policy at
          any time and for any reason. We will alert you about any changes by
          updating the “Last Updated” date of this Privacy Policy. Any changes
          or modifications will be effective immediately upon posting the
          updated Privacy Policy on the Site, and you waive the right to receive
          specific notice of each such change or modification. You are
          encouraged to periodically review this Privacy Policy to stay informed
          of updates. You will be deemed to have been made aware of, will be
          subject to, and will be deemed to have accepted the changes in any
          revised Privacy Policy by your continued use of the Site after the
          date such revised Privacy Policy is posted.
        </p>

        <h3>
          <span>1.</span> Collection of Personal Data
        </h3>
        <p>
          We may collect information about you in a variety of ways. The
          information we may collect includes personally identifiable
          information, such as your name, shipping address, email address, and
          telephone number, and demographic information, such as your age,
          gender, hometown, and interests, that you voluntarily give to us when
          you register with the Site or when you choose to participate in
          various activities related to the Site such as message boards or when
          you expressly grant us access or permission. You are under no
          obligation to provide us with personal information of any kind,
          however, your refusal to do so may prevent you from using certain
          features of the Site. We may also collect device information, such as
          your mobile device ID, model, and manufacturer, and information about
          the location of your device if you access the Site from a mobile
          device.
        </p>
        <h3>
          <span>1.1</span> Consent
        </h3>
        <p>
          Obtaining Consent: Consent for the collection, processing, and sharing
          of personal data is obtained through clear affirmative actions such as
          ticking checkboxes, opting in through dialogue boxes, or agreeing to
          specific terms presented. Scope of Consent: Consent extends to all
          personal data collected via our services, including account
          registration and service usage, aligning with the details provided in
          our Cookies Policy. Withdrawal of Consent: Users can withdraw their
          consent at any time through account settings or by contacting us
          directly. Withdrawal may impact the functionality of our services.
        </p>
        <h3>
          <span>2.</span> Use of Your Information{" "}
        </h3>
        <p>
          Having accurate information about you permits us to provide you with a
          smooth, efficient, and customized experience, based on your choice.
          Specifically, we may use information collected about you to:
        </p>
        <ul>
          <li> Administer promotions, and contests. </li>
          <li> Create and manage your account.</li>
          <li>
            {" "}
            Deliver targeted advertising, coupons, newsletters, and other
            information regarding promotions of the Site to you.{" "}
          </li>
          <li> Email you regarding your account or order.</li>
          <li> Enable user-to-user communications.</li>
          <li>
            {" "}
            Fulfill and manage purchases, orders, payments, and other
            transactions related to the Site.
          </li>
          <li>
            {" "}
            Generate a personal profile about you to make future visits to the
            Site more personalized.
          </li>
          <li> Increase the efficiency and operation of the Site.</li>
          <li>
            {" "}
            Monitor and analyze usage and trends to improve your experience with
            the Site.
          </li>
          <li> Notify you of updates to the Site.</li>
          <li> Offer new products, services, and recommendations to you.</li>
          <li> Perform other business activities as needed.</li>
          <li>
            {" "}
            Prevent fraudulent transactions, monitor against theft, and protect
            against criminal activity.
          </li>
          <li> Process payments and refunds.</li>
          <li> Request feedback and contact you about your use of the Site.</li>
          <li> Resolve disputes and troubleshoot problems.</li>
          <li> Respond to product and customer service requests.</li>
          <li> Send you a newsletter.</li>
          <li> Solicit support for the Site.</li>
        </ul>
        <h3>
          <span>2.1</span> Data Retention Policy{" "}
        </h3>
        <p>
          Our Data Retention Policy is designed to help users understand how
          long their data will be kept and the criteria used to determine these
          periods.
        </p>
        <p>
          Retention Periods: FireFlink retains personal data only for as long as
          necessary to fulfill the purposes for which it was collected,
          including to satisfy any legal, regulatory, tax, accounting, or
          reporting requirements. Data may be retained for a longer period in
          the event of a complaint or if we reasonably believe there is a
          prospect of litigation with respect to our relationship with the user.
        </p>
        <p>
          Criteria for Retention: The period for which personal data is stored
          depends on the nature of the information and the purposes for which it
          is processed. The retention period is based on several criteria,
          including:
        </p>
        <ul>
          <li>
            {" "}
            The length of time necessary to provide the services requested by
            the user.
          </li>
          <li>
            {" "}
            Whether the user has an account with us that requires personal
            information.
          </li>
          <li>
            {" "}
            The type of data collected and the need to maintain its accuracy and
            integrity.
          </li>
          <li>
            {" "}
            Legal and regulatory obligations that require data to be retained
            for specific periods.
          </li>
        </ul>
        <p>
          Upon expiration of the retention period, personal data shall be
          deleted or anonymized in a manner that ensures it can no longer be
          used to identify the user unless further processing is required for
          legal or regulatory reasons.
        </p>
        <h3>
          <span>3.</span> Disclosure of Your Information{" "}
        </h3>
        <p>
          We may share information we have collected about you in certain
          situations. Your information may be disclosed as follows:
        </p>
        <h3>
          <span>3.1</span> By Law or to Protect Rights{" "}
        </h3>
        <p>
          If we believe the release of information about you is necessary to
          respond to legal process, to investigate or remedy potential
          violations of our policies, or to protect the rights, property, and
          safety of others, we may share your information as permitted or
          required by any applicable law, rule, or regulation. This includes
          exchanging information with other entities for fraud protection, if so
          required, in the specific circumstances of any case.
        </p>
        <h3>
          <span>3.2</span> Third-Party Service Providers{" "}
        </h3>
        <p>
          We may share your information with third parties that perform services
          for us or on our behalf, including payment processing, data analysis,
          email delivery, hosting services, customer service, and marketing
          assistance. The types of data analysis conducted by these third
          parties include but are not limited to:
        </p>
        <p>
          User Engagement Patterns and Usage Statistics: To identify trends and
          improve our services, data is analyzed in an aggregated or anonymized
          form, ensuring individual users cannot be identified, thus
          significantly reducing privacy concerns.
        </p>
        <p>
          Operational Efficiency: We leverage data analysis to enhance our
          operational processes, ensuring a seamless experience for our users.
        </p>
        <p>
          User Experience Enhancement: Analysis focuses on how users interact
          with our software, informing design improvements to make FireFlink
          more intuitive and user-friendly.
        </p>
        <p>
          Security and Fraud Detection: We analyze behavioral patterns that
          deviate from the norm to detect and prevent security threats or
          fraudulent activity, safeguarding our users and our platform.
        </p>
        <p>
          Customization and Personalization: Where applicable, we use data
          analysis to tailor the user experience, offering more relevant
          features or recommendations based on user preferences and usage
          patterns.
        </p>
        <h3>
          <span>3.3</span> Marketing Communications{" "}
        </h3>
        <p>
          With your consent, or with an opportunity for you to withdraw consent,
          we may share your information with third parties for marketing
          purposes, as permitted by law.
        </p>
        <h3>
          <span>3.4</span> Third-Party Advertisers{" "}
        </h3>
        <p>
          We may share your information with vendors, including our affiliates
          and other third parties, to enhance and develop our services. This
          sharing is aimed at operational business needs, service improvement,
          product development, marketing, and offering you tailored products,
          services, or promotions. Specifically:
        </p>
        <h3>Information Sharing with Vendors and Third Parties </h3>
        <p>
          We may share your information with vendors, including our affiliates
          and other third parties, to enhance and develop our services. This
          sharing is aimed at operational business needs, service improvement,
          product development, marketing, and offering you tailored products,
          services, or promotions. Specifically:
        </p>
        <p>
          Affiliates: Including our parent company, subsidiaries, joint venture
          partners, or other companies under our control, for purposes aligned
          with this Privacy Policy.
        </p>
        <p>
          Other Third Parties: This encompasses both business partners and
          third-party entities such as advertisers and investors, for general
          business analysis, and marketing purposes to offer you specific
          promotions, all based on your consent and as permitted by law.
        </p>
        <p>
          In all cases, our commitment to protecting your information remains
          paramount. We ensure that any sharing is conducted under strict
          privacy standards, respecting your rights and adhering to applicable
          legal requirements.
        </p>

        <h3>
          <span>4.</span> Security
        </h3>
        <p>
          We use administrative, technical, and physical security measures to
          help protect your personal information. While we have taken reasonable
          steps to secure the personal information you provide to us, please be
          aware that despite our efforts, no security measures are perfect or
          impenetrable, and no method of data transmission can be guaranteed
          against any interception or other type of misuse. Any information
          disclosed online is vulnerable to interception and misuse by
          unauthorized parties. Therefore, we cannot guarantee complete security
          if you provide personal information. For the avoidance of doubt, if
          any personal information is compromised despite our efforts to prevent
          the occurrence of the same, you shall not hold us liable for such
          breach of privacy protection under this privacy policy.
        </p>
        <h3>
          <span>4.1</span> Use of Fake Data for Testing{" "}
        </h3>
        <p>
          FireFlink's platform allows for the use of fake data for testing
          purposes. This enables clients to simulate real-world scenarios
          effectively without using real personal information or Personally
          Identifiable Information (PII), thus ensuring the privacy and security
          of actual customer data are never compromised. Our approach highlights
          our commitment to data protection, underlining our dedication to
          privacy by design and secure data handling practices.
        </p>

        <h3>
          <span>5.</span> Children’s Privacy
        </h3>
        <p>
          We do not aim any of our products or services directly at children
          under the age of 16, and we do not knowingly collect personal
          information about children under 16. If you become aware that someone
          under the age of 16 has unlawfully provided us with personal data,
          please reach out to us, and we will promptly take measures to delete
          such information.
        </p>
        <h3>
          <span>5.1</span> Emails and Communications{" "}
        </h3>
        <p>
          If you no longer wish to receive correspondence, emails, or other
          communications from us, you may opt-out by:
        </p>
        <ul>
          <li>
            {" "}
            Noting your preferences at the time you register your account with
            the Site.
          </li>
          <li>
            {" "}
            Logging into your account settings and updating your preferences.
          </li>
          <li> Contacting us using the contact information provided below.</li>
        </ul>
        <h3>
          <span>6.</span> Exercise Your Rights
        </h3>
        <p>
          We respect your right to be informed, access, correct, request
          deletion or request restriction, portability, objection, and rights
          about automated decision-making and profiling, in our usage of your
          personal information as required by applicable law. We also take steps
          to ensure that the personal information we collect is accurate and up
          to date and deleted when data is no longer required. Your rights are
          subject to defined conditions and feasibility to exercise such rights
          and are subject to geolocations' applicability.
        </p>
        <p>Your Rights:</p>
        <ul className="steps">
          <li>
            {" "}
            <span>Right to Know: </span>
            You have the right to know what personal information we maintain
            about you subject to availability.
          </li>
          <li>
            {" "}
            <span>Right to Data Portability: </span>
            We will provide you with a copy of your personal information in a
            structured, commonly used, and machine-readable format on request
            subject to availability.
          </li>
          <li>
            {" "}
            <span>Right of Rectification: </span>
            If your personal information is incorrect or incomplete, you have
            the right to review it and ask us to update it.
          </li>
          <li>
            {" "}
            <span>Right to Object: </span>
            You have the right to object to our processing of your personal
            information only if the data is processed.
          </li>
          <li>
            {" "}
            <span>Right of Deletion: </span>
            You can also ask us to delete or restrict how we use your personal
            information, but this right is subject to and determined by
            applicable law and may impact your access to some of our services.
          </li>
          <li>
            {" "}
            <span>Right to Access: </span>
            You have the right to access your personal information which may
            vary from visitor to visitor.
          </li>
          <li>
            {" "}
            <span>Right to Restriction of Processing: </span>
            You can ask us to stop using all or some of your personal
            information (e.g., if we have no legal right to keep using it) or to
            limit our use of it (e.g. if you think your personal information is
            inaccurate or unlawfully held). Following are the processings you
            can use this right if:
            <ul>
              <li>
                you contest the accuracy of the personal data being processed,
              </li>
              <li>
                the processing is unlawful and you oppose the erasure of your
                data and request the restriction of their use instead,
              </li>
              <li>
                our customer, as the data controller, no longer needs your data
                for processing, but you have required them for the
                establishment, exercise, or defense of legal claims, and
              </li>
              <li>
                you object to processing pending the verification of whether the
                legitimate grounds of the data controller (our customer)
                override yours
              </li>
            </ul>
          </li>
          <li>
            <span>
              Automated individual decision-making, including profiling:{" "}
            </span>
            You have a right to object to the processing of your personal
            information where it is so conducted by automated means and involves
            any kind of decision-making subject to the discovery that your data
            was used for automated means.
          </li>
          <li>
            <span>Right to withdraw consent: </span>You have the right to
            withdraw your consent at any time with effect for the future. The
            withdrawal of consent will not affect the lawfulness of processing
            based on consent before its withdrawal.
          </li>
          <li>
            <span>Right to lodge a complaint: </span>You have the right to lodge
            a complaint with the (public) supervisory authority if you are
            dissatisfied with the way we handle or process your data.
          </li>
        </ul>

        <h3>
          <span>7.</span> Use of Cookies
        </h3>
        <p>
          We use “cookies” to collect information about you and your activity
          across our site. A cookie is a small piece of data that our website
          stores on your computer, and accesses each time you visit, so we can
          understand how you use our site. This helps us serve you content based
          on the preferences you have specified.
        </p>
        <p>Please refer to our Cookie Policy for more information.</p>

        <h3>
          <span>8.</span> Cross-Border Data Transfers
        </h3>
        <p>
          Your Personal Information may be transferred to and maintained on,
          computers located outside of your state, province, country, or other
          governmental jurisdiction where the privacy laws are different from
          the laws of your country and may not be as protective as those in your
          jurisdiction.
        </p>
        <p>
          These international transfers of your personal information are made
          according to the appropriate safeguards such as the standard data
          protection clauses adopted by the European Commission. These clauses
          are contractual commitments between parties transferring personal data
          (for example, between Fireflink and its Clients, suppliers, or data
          processors outside the EU), binding them to protect the privacy and
          security of the data.
        </p>
        <h3>
          <span>9.</span> Contact Us
        </h3>
        <p>
          If you have questions, comments, or complaints about this Privacy
          Policy or if any action is required concerning your personal
          information, please contact us at{" "}
          <a className="prodcolor" href="mailto:support@fireflink.com">
            support@fireflink.com
          </a>
          .{" "}
        </p>
        <p>
          We may contact you for marketing, promotional activities, or general
          updates. By accepting our Privacy Policy, you consent to receive such
          communications from us. You can opt out of these communications at any
          time.
        </p>
        <h3>Data Protection Officer (DPO) and Grievance Officer</h3>
        <p>
          FireFlink has appointed a Data Protection Officer (DPO) to oversee
          data protection strategy and implementation to ensure compliance with
          data protection laws.
        </p>
        <p>
          You can contact our DPO at:{" "}
          <a className="prodcolor" href="mailto:dpo@fireflink.com">
            dpo@fireflink.com
          </a>
        </p>
        <p>
          Grievance Officer: For any complaints or concerns regarding privacy
          practices, please reach out to{" "}
          <a
            className="prodcolor"
            href="mailto:customersuccessmanager@fireflink.com"
          >
            customersuccessmanager@fireflink.com
          </a>
          .
        </p>
      </>
    );
  };
  return (
    <Modal
      show={show}
      // onHide={handleClose}
      backdrop="static"
      size="xl"
      className={`term_modal_dialog ${className}`}
      // onShow={() => window.addEventListener('scroll', handleScroll)}
      // onHide={() => window.removeEventListener('scroll', handleScroll)}
    >
      <div className="">
        <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
          <Modal.Header>
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="tab1">TERMS OF USE</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tab2">PRIVACY POLICY</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tab3">EULA</Nav.Link>
              </Nav.Item>
            </Nav>
          </Modal.Header>
          <Modal.Body className="modal-body custom-scrollbar">
            <div className="term_tab"></div>
            <Tab.Content>
              <Tab.Pane eventKey="tab1">{modalContent1()}</Tab.Pane>
              <Tab.Pane eventKey="tab2">{modalContent3()}</Tab.Pane>
              <Tab.Pane eventKey="tab3">{modalContent2()}</Tab.Pane>
            </Tab.Content>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            {/* <Button disabled={!isBottom} variant="primary" onClick={iAgree} >I Agree</Button> */}
          </Modal.Footer>
        </Tab.Container>
      </div>
    </Modal>
  );
}

export default ModalComp;
